import React from "react";
import '../styles/WhatWeOffer.css';
import { Link } from 'react-router-dom';
import individual from "../assets/individual.png";
import individual1 from "../assets/individual1.png";
import individual2 from "../assets/individual2.png";
import individual3 from "../assets/individual3.png";

const WhatWeOffer = () => {
    return (
        <div className="whatweoffer">
            <div className="individual-savins">
                <div className="card">
                    <div className="left-introduction">
                        <h1>Clarity</h1>
                        <p>Kiota provides every working individual in Kenya <br></br> with access to a retirement account, eliminating <br></br>friction points for financial advisors and companies<br></br> in establishing and maintaining defined contribution <br></br>and benefit programs.</p>
                    </div>
                    <div className="right-introduction">
                        <Link to=""><img src={individual} alt="individualimage" /></Link>
                    </div>
                </div>
                <div>
                    <section className="display-icons">
                        <div className="image-1">
                            <Link to=""><img src={individual1} alt="individualimage" /></Link>
                        </div>
                        <div className="image-2">
                            <Link to=""><img src={individual2} alt="individualimage1" /></Link>
                        </div>
                        <div className="image-3">
                            <Link to=""><img src={individual3} alt="individualimage2" /></Link>
                        </div>
                    </section>
                </div>
                <div className="howw-it-works">
                    <div className="card">
                        <div className="left-introduction-work">
                            <h1> Accountability</h1>
                            <p> Our values are a way of doing business—not just a plaque on the wall. We place the highest value on:
                            Earning our clients' trust by treating them in an ethical, empathetic, and proactive way.</p>
                        </div>
                        <div className="righti-introduction">
                            <p> Respecting fellow employees and reinforcing <br></br> the power  of teamwork.Being good stewards <br></br> of our brand  and stockholder values</p>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="benefits-retirement">
                        <h1>We prioritize the best interests of financial advisors, companies, and employees.</h1>
                    </div>
                </div>
                <div className="how-it-works">
                    <div className="card">
                        <div className="automate">
                            <h1> Automation</h1>
                            <p> Kiota's digital platform enables seamless plan design, automated onboarding, and low-cost investment strategies, facilitating easier retirement plan offerings for employers.</p>
                        </div>
                        <div className="extension">
                            <p>Kiota becomes an extension of the financial advisor’s services, acting in everyone’s best interest, while scaling through technology and allowing financial advisors to focus on clients.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WhatWeOffer;
