import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import '../styles/Saver.css';
import desktop from "../assets/Desktop.png";
import SaverCards from "../components/SaverCards";
import saverf from "../assets/savef.png";
import vector from "../assets/Vector.png";
import individual from "../assets/individual.png";

const Saver = () => {
  return (
    <div className="saver-page">
      <div className="log">
        <div className="card">
          <div className="left-intro">
            <Link to=""><img src={vector} alt="values" /> </Link>
            <h1> Individual Savings</h1>
            <p>Saving for retirement is important. We're here to help</p>
            <div className="login-container">
              <Link to="/Login"><button >Login</button></Link>
            </div>
          </div>
          <div className="right-intro">
            <Link to=""><img src={individual} alt="individualimage" /> </Link>
          </div>
        </div> 
      </div>

      <div className="saver">
        <div className="card">
          <div className="left-part">
            <h1>Benefits for you</h1>
            <div className="btn-save">
              <h2> 
                <FontAwesomeIcon icon={faCheck} className="green-check" />  24/7 Support From Kiota
                <br />
                <FontAwesomeIcon icon={faCheck} className="green-check" /> Secure Investments
                <br />
                <FontAwesomeIcon icon={faCheck} className="green-check" /> Investments Analysis Every Month
                <br />
                <FontAwesomeIcon icon={faCheck} className="green-check" /> Saving Money for the Future
                <br />
                <FontAwesomeIcon icon={faCheck} className="green-check" /> Expert Wealth Advice
              </h2>
              <div className="button-more">
                <button onClick={() => window.location.href = '/SaversOnboardingPage'}>
                  Find Out More
                </button>
              </div>
              <div className="right-part">
                <img src={desktop} alt="" />
              </div>
              <div className="closer11">
                <Link to="/foot" className="image-linker">
                  <img src={saverf} alt="foot" />
                </Link>
              </div>
            </div>
          </div>

          <div className="savercard-input">
            <SaverCards /> 
          </div>
        </div>
      </div>
    </div>
  );
};

export default Saver;
