// Layout.js
import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import Banner from './Banner';

const Layout = ({ children, excludeBannerNavbar, excludeFooter }) => {
 return (
    <div>
      {!excludeBannerNavbar && <Banner />}
      {!excludeBannerNavbar && <Navbar />}
      <div>{children}</div>
      {!excludeFooter && <Footer />}
    </div>
 );
};

export default Layout;
