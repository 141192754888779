import "../styles/SectionText.css";

const SectionText = ({
  iconJamIconsOutlineLogos,
  iconJamIconsOutlineLogos1,
  iconJamIconsOutlineLogos2,
  iconJamIconsOutlineLogos3,
  iconJamIconsOutlineLogos4,
  iconJamIconsOutlineLogos5,
  iconLeft,
  text,
  iconRight,
}) => {
  return (
    <div className="section-text">
      <div className="top1">
        <h3 className="caption1">Services</h3>
        <h1 className="secondary-headline1">
          Built for the modern day investor
        </h1>
      </div>
      <div className="buttons-group2">
        <div className="button-11">
          <img
            className="icon-jam-icons-outline-l19"
            alt=""
            src={iconJamIconsOutlineLogos}
          />
          <div className="text-container10">
            <div className="button-text10">Button Text</div>
          </div>
          <img
            className="icon-jam-icons-outline-l20"
            alt=""
            src={iconJamIconsOutlineLogos1}
          />
        </div>
        <div className="button-21">
          <img
            className="icon-jam-icons-outline-l21"
            alt=""
            src={iconJamIconsOutlineLogos2}
          />
          <div className="text-container11">
            <div className="button-text11">Button Text</div>
          </div>
          <img
            className="icon-jam-icons-outline-l22"
            alt=""
            src={iconJamIconsOutlineLogos3}
          />
        </div>
        <div className="button-31">
          <img
            className="icon-jam-icons-outline-l23"
            alt=""
            src={iconJamIconsOutlineLogos4}
          />
          <div className="text-container12">
            <div className="button-text12">Button Text</div>
          </div>
          <img
            className="icon-jam-icons-outline-l24"
            alt=""
            src={iconJamIconsOutlineLogos5}
          />
          <div className="badge4">
            <div className="text5">9</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionText;
