import React from "react";
import "../styles/About.css";
import { Link } from "react-router-dom";
import AboutL from "../assets/AboutL.png";
import mission from "../assets/mission.png";
import vision from "../assets/Vision.png";
import values from "../assets/Values.png";

const About = () => {
  return (
    <div className="about-page">
      <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
      <div className="header">
        <h1> About us </h1>
      

      <div className="aboutL">
        <Link to="">
            <img src={AboutL} alt="AboutL" />
          </Link>
        <div className="right">
        <h1> Who we are </h1>
        <p>We believe that Kenyan workers should <br></br>have easy access to an inexpensive, flexible,<br></br> intuitive solution to save for a brighter future.<br></br> </p>
        </div>
      </div>
        <div className=" hero2">
        <div className="mission">
        <h1> Mission </h1>
    <p>We are here to empower people to do what's best for their money so they can live better.</p>
        
        <Link to="">
            <img src={mission} alt="mission" />
          </Link>
        </div>
        <div className="vision">
        <h1> Vision </h1>
        <p>To empower individuals and corporations with robust retirement planning solutions, ensuring a financially secure and fulfilling future for all.</p>
        <Link to="">
            <img src={vision} alt="vision" />
          </Link>
        </div>
        
        <div className="values">
        <h1> Values </h1>
        <p>Our values are a way of doing business—not just a plaque on the wall. We place the highest value on:</p>
            <ul>
            <li>Earning our clients' trust by treating them in an ethical, empathetic, and proactive way.</li>
            <li>Constantly improving the client experience through innovation that benefits clients.</li>
            <li>Respecting fellow employees and reinforcing the power of teamwork.</li>
            <li>Being good stewards of our brand and stockholder values.</li>
          </ul>

        <Link to="">
            <img src={values} alt="values" />
          </Link>
        </div>
        </div>
        <div className="rectangle-box">
              <div className='rect'>
             
              </div>
              <h2>Gain value through Kiota Platform</h2>
              <Link to="/kiota-demo"> <button>Book a Demo</button></Link>
             </div>
         </div>
    </div>
  );
}
export default About;