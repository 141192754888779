import React from "react";
import { Link } from "react-router-dom";
import frame1 from "../assets/frame1.png";
import vector from "../assets/Vector.png";
import foot from "../assets/foot.png";
import '../styles/Homepage.css';
import WriteYourRetirementStory from "../components/GetStarted";
// import GetStarted from "../components/GetStarted";


const Homepage = () => {
  return (
    <div className="home">
      <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
      <section className="hero">
        <div className="text-content">
          <Link to=""><img src={vector} alt="vector" /></Link>
          <h1>The Retirement Experience <br></br> Everyone Deserves</h1>
          <h2> 80% of the labor force in Kenya remains without a pension coverage. This implies 8 of 10 individuals have no access to essential retirement savings and will have no financial security in their later years.</h2>
          <WriteYourRetirementStory  to="/RetirementStory" /> 
        </div>
        <div className="image-content">
          <Link to="">
            <img src={frame1} alt="frame1" />
          </Link>
        </div>
      </section>
      <section className="contribute">
        <div className="contribute-content">
      <h1> We help you plan and contribute for your retirement</h1>
        </div>
        <div className="button-container">
          <div className="button-group">
            <Link to="/DashboardEmployer" className="yellow-background">
              <button> Savers </button>
            </Link>
            <Link to="/Company">
              <button> Companies</button>
            </Link>
            <Link to="/kiotaTPAs">
              <button>TPAs</button>
            </Link>
          </div>
        </div>
      </section>
     <section className="prompt">
        <div className="card">
          <h1>Putting You on the Path Towards Financial Health</h1>
          <p>For most Kenyans, a workplace retirement plan is projected to be the backbone of their investing <br></br> strategy. We are making retirement plans easier to offer and administer.</p>
          <div className="navigate">
            <div className="get-started-button1">
             <WriteYourRetirementStory to="/RetirementStory"/></div>
            <a href="/saver" className="saver-schedule-demo-button"> Plan a future</a>
          </div>
        </div>
        <div className="close">
          <Link to="/foot" className="image-linker">
            <img src={foot} alt="foot" />
          </Link>
        </div>
      </section>
    </div>
  );
};


export default Homepage;
