import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../styles/KiotaLogin.css";

const Login = ({ className = "" }) => {
  const [userType, setUserType] = useState("Employee");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");

    try {
      const response = await axios.post("http://localhost:3500/api/auth/login", {
        username,
        password,
      });

      const { user, token } = response.data;

      // Store the token in localStorage
      localStorage.setItem("token", token);

      // Redirect based on user type
      if (userType === "Employee" && user.role === "saver") {
        navigate("/DashboardSaver");
      } else {
        // Handle other user types or show an error
        setError("Invalid user type or role");
      }
    } catch (error) {
      setError("Invalid credentials");
    }
  };

  return (
    <div className={`kiota-login ${className}`}>
      <div className="login-content">
        <h1 className="kiota-logo">Kiota</h1>
        <h2 className="tagline">Putting you on the path<br />towards financial health</h2>
        <div className="tagline-underline"></div>
      </div>
      <div className="login-form-container">
        <h3 className="login-title">Log in</h3>
        <Form onSubmit={handleLogin}>
          <Form.Select 
            className="login-select" 
            value={userType} 
            onChange={(e) => setUserType(e.target.value)}
          >
            <option value="Employee">Employee</option>
            <option value="Company">Company</option>
            <option value="TPA">TPA</option>
          </Form.Select>
          <Form.Control 
            type="text" 
            placeholder="Username" 
            className="login-input" 
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <Form.Control 
            type="password" 
            placeholder="Password" 
            className="login-input" 
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className="forgot-password">Forgot password?</div>
          <Button className="login-button" variant="primary" type="submit">Login</Button>
        </Form>
        {error && <div className="error-message">{error}</div>}
      </div>
    </div>
  );
};

export default Login;