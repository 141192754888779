import SectionText from "./SectionText";
import Row1 from "./Row1";
import Row from "./Row";
import "../styles/FrameComponent8.css";

const FrameComponent8 = () => {
  return (
    <div className="section-text-parent">
      <SectionText
        iconJamIconsOutlineLogos="/icon--jamicons--outline--logos--plus.svg"
        iconJamIconsOutlineLogos1="/icon--jamicons--outline--logos--arrowright.svg"
        iconJamIconsOutlineLogos2="/icon--jamicons--outline--logos--plus1.svg"
        iconJamIconsOutlineLogos3="/icon--jamicons--outline--logos--arrowright-1.svg"
        iconJamIconsOutlineLogos4="/icon--jamicons--outline--logos--plus1.svg"
        iconJamIconsOutlineLogos5="/icon--jamicons--outline--logos--arrowright-1.svg"
        iconLeft={false}
        text
        iconRight
      />
      <Row1
        picture="/picture@2x.png"
        iconIconoirTruck="/icon--iconoir--truck1.svg"
        
        iconIconoirStatUp="/icon--iconoir--statup.svg"
        iconJamIconsOutlineLogos1="/icon--jamicons--outline--logos--pencil.svg"
        picture1="/picture@2x.png"
        userThumb="/user-thumb@2x.png"
        iconJamIconsOutlineLogos2="/icon--jamicons--outline--logos--plus.svg"
        iconJamIconsOutlineLogos3="/icon--jamicons--outline--logos--arrowright-31.svg"
        iconJamIconsOutlineLogos4="/icon--jamicons--outline--logos--plus.svg"
        iconJamIconsOutlineLogos5="/icon--jamicons--outline--logos--arrowright-41.svg"
        iconJamIconsOutlineLogos6="/icon--jamicons--outline--logos--circle1.svg"
        iconIconoirCancel="/icon--iconoir--cancel.svg"
        userThumb1="/user-thumb1@2x.png"
        picture2="/picture@2x.png"
        iconIconoirTrophy="/icon--iconoir--trophy1.svg"
        iconJamIconsOutlineLogos7="/icon--jamicons--outline--logos--circle.svg"
        iconIconoirStatUp1="/icon--iconoir--statup.svg"
        iconJamIconsOutlineLogos8="/icon--jamicons--outline--logos--pencil1.svg"
        picture3="/picture@2x.png"
        userThumb2="/user-thumb-1@2x.png"
        iconJamIconsOutlineLogos9="/icon--jamicons--outline--logos--plus.svg"
        iconJamIconsOutlineLogos10="/icon--jamicons--outline--logos--arrowright-31.svg"
        iconJamIconsOutlineLogos11="/icon--jamicons--outline--logos--plus.svg"
        iconJamIconsOutlineLogos12="/icon--jamicons--outline--logos--arrowright-41.svg"
        iconJamIconsOutlineLogos13="/icon--jamicons--outline--logos--circle1.svg"
        iconIconoirCancel1="/icon--iconoir--cancel.svg"
        userThumb3="/user-thumb2@2x.png"
        propGap="2.25em"
        propMinWidth="12.75em"
        propHeight="3em"
        propWidth="3em"
        propMinWidth1="10.88em"
        propPadding="0.75em 0.56em"
        propMinWidth2="6.75em"
        propPadding1="0.75em 0.56em"
        propMinWidth3="6.75em"
        propMinWidth4="12.75em"
        propHeight1="3em"
        propWidth1="3em"
        propWidth2="unset"
        propMinWidth5="10.88em"
        propPadding2="0.75em 0.56em"
        propMinWidth6="6.75em"
        propPadding3="0.75em 0.56em"
        propMinWidth7="6.75em"
        iconLeft={false}
        text
        iconRight
      />
      <Row
        picture="/picture@2x.png"
        iconIconoirTrekking="/icon--iconoir--trekking1.svg"
        iconJamIconsOutlineLogos="/icon--jamicons--outline--logos--circle.svg"
        iconIconoirStatUp="/icon--iconoir--statup.svg"
        iconJamIconsOutlineLogos1="/icon--jamicons--outline--logos--pencil2.svg"
        picture1="/picture@2x.png"
        userThumb="/user-thumb@2x.png"
        iconJamIconsOutlineLogos2="/icon--jamicons--outline--logos--plus.svg"
        iconJamIconsOutlineLogos3="/icon--jamicons--outline--logos--arrowright-31.svg"
        iconJamIconsOutlineLogos4="/icon--jamicons--outline--logos--plus.svg"
        iconJamIconsOutlineLogos5="/icon--jamicons--outline--logos--arrowright-41.svg"
        iconJamIconsOutlineLogos6="/icon--jamicons--outline--logos--circle1.svg"
        iconIconoirCancel="/icon--iconoir--cancel.svg"
        userThumb1="/user-thumb1@2x.png"
        picture2="/picture@2x.png"
        iconIconoirPiggyBank="/icon--iconoir--piggybank1.svg"
        iconJamIconsOutlineLogos7="/icon--jamicons--outline--logos--circle.svg"
        iconIconoirStatUp1="/icon--iconoir--statup.svg"
        iconJamIconsOutlineLogos8="/icon--jamicons--outline--logos--pencil2.svg"
        picture3="/picture@2x.png"
        userThumb2="/user-thumb-1@2x.png"
        iconJamIconsOutlineLogos9="/icon--jamicons--outline--logos--plus.svg"
        iconJamIconsOutlineLogos10="/icon--jamicons--outline--logos--arrowright-31.svg"
        iconJamIconsOutlineLogos11="/icon--jamicons--outline--logos--plus.svg"
        iconJamIconsOutlineLogos12="/icon--jamicons--outline--logos--arrowright-41.svg"
        iconJamIconsOutlineLogos13="/icon--jamicons--outline--logos--circle1.svg"
        iconIconoirCancel1="/icon--iconoir--cancel.svg"
        userThumb3="/user-thumb2@2x.png"
        propGap="2.25em"
        propMinWidth="12.75em"
        propHeight="3em"
        propWidth="3em"
        propMinWidth1="12.75em"
        propHeight1="3em"
        propWidth1="3em"
        iconLeft={false}
        text
        iconRight
      />
    </div>
  );
};

export default FrameComponent8;
