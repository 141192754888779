import GainValueCompany from "../components/GainValueCompany";
import "../styles/Company.css";
// import caption-text-icon from "../assets/vector@2x.png";
import { useEffect, useState } from "react";

const KiotaCompanySavings = () => {
  return (
    <div className="kiota-company-savings">
      <section className="vector-savings-frame">
        <div className="category-icon-title-box"> 
          <div className="divider-rectangle">
            <div className="divider-rectangle-child" />
            <div className="round-frames">
              <div className="ellipse-frames">
                <div className="row-content">
                  <div className="top-frame">
                    <img
                      className="caption-text-icon"
                      loading="eager"
                      alt=""
                      src="/vector@2x.png"
                    />
                    <h1 className="company-savings">Company savings</h1>
                  </div>
                  <div className="saving-for-retirement">
                    Saving for retirement is important. We're here to help.
                  </div>
                </div>
                <div className="footer-big-frame">
                  <button className="asset-container">
                    <div className="rectangle-footer">
                      <div className="log-in">Log in</div>
                    </div>
                  </button>
                  <div className="individual-employee-company-t1">
                  <a href="/kiotaTPas" className="schedule-demo-btn"> Plan a future</a>
                  <div>
                    <span>&#8594;</span>
                  </div>
                  </div>
                </div>
              </div>
            </div>
            <img
              className="b6cd62-e56e-4e10-bd73-eb34cf78-icon"
              loading="eager"
              alt=""
              src="/-41b6cd62e56e4e10bd73eb34cf78ea5e@2x.png"
            />
          </div>
          <div className="row-content-box-frame">
            <div className="content-box-frame">
              <div className="icon-frame">
                <div className="title-category-frame" />
                <div className="paragraph-frame">
                  <div className="vector-frame" />
                  <div className="wrapper-company-savings-text">
                    <img
                      className="company-savings-text1"
                      loading="eager"
                      alt=""
                      src="/frame-35@2x.png"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row-frame">
              <div className="section-text">
                <div className="top">
                  <h3 className="caption">Services</h3>
                  <div className="main-headline">Headline One</div>
                  <h1 className="secondary-headline">
                    Built for the modern day investor
                  </h1>
                </div>
                <div className="paragraph">
                  Pretium lectus ultrices sit tempor, sit ullamcorper volutpat
                  et et. Auctor turpis semper id sit ornare maecenas lectus sed.
                </div>
                <div className="buttons-group">
                  <div className="button-1">
                    <img
                      className="icon-jam-icons-outline-l"
                      alt=""
                      src="/icon--jamicons--outline--logos--plus.svg"
                    />
                    <div className="text-container">
                      <div className="button-text">Button Text</div>
                    </div>
                    <img
                      className="icon-jam-icons-outline-l1"
                      alt=""
                      src="/icon--jamicons--outline--logos--arrowright.svg"
                    />
                  </div>
                  <div className="button-2">
                    <img
                      className="icon-jam-icons-outline-l2"
                      alt=""
                      src="/icon--jamicons--outline--logos--plus.svg"
                    />
                    <div className="text-container1">
                      <div className="button-text1">Button Text</div>
                    </div>
                    <img
                      className="icon-jam-icons-outline-l3"
                      alt=""
                      src="/icon--jamicons--outline--logos--arrowright-1.svg"
                    />
                  </div>
                  <div className="button-3">
                    <img
                      className="icon-jam-icons-outline-l4"
                      alt=""
                      src="/icon--jamicons--outline--logos--plus.svg"
                    />
                    <div className="text-container2">
                      <div className="button-text2">Button Text</div>
                    </div>
                    <img
                      className="icon-jam-icons-outline-l5"
                      alt=""
                      src="/icon--jamicons--outline--logos--arrowright-1.svg"
                    />
                    <div className="badge">
                      <div className="text">9</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="content-box">
                  <img className="picture-icon" alt="" src="/picture@2x.png" />
                  <div className="content">
                    <div className="icon">
                      <img
                        className="icon-iconoir-truck"
                        loading="eager"
                        alt=""
                        src="/icon--iconoir--truck@2x.png"
                      />
                    </div>
                    <div className="title-category1">
                      <div className="category"></div>
                      <div className="title-container">
                        <h3 className="title">
                          {`Easy `}
                          <span className="enrollment">ENROLLMENT</span>
                        </h3>
                        <div className="badge1">
                          <img
                            className="icon-jam-icons-outline-l6"
                            alt=""
                            src="/icon--jamicons--outline--logos--circle.svg"
                          />
                          <div className="text-container3">
                            <div className="button-text3">+2,5%</div>
                          </div>
                          <img
                            className="icon-iconoir-stat-up"
                            alt=""
                            src="/icon--iconoir--statup.svg"
                          />
                        </div>
                        <img
                          className="icon-jam-icons-outline-l7"
                          alt=""
                          src="/icon--jamicons--outline--logos--pencil.svg"
                        />
                      </div>
                    </div>  
                    <img
                      className="picture-icon1"
                      alt=""
                      src="/picture@2x.png"
                    />
                    <div className="paragraph1">
                      With completely digital enrollment and deferral selection,
                      we make it easy for you to hit the ground saving.
                    </div>
                    <div className="user-card">
                      <img
                        className="user-thumb-icon"
                        alt=""
                        src="/user-thumb@2x.png"
                      />
                      <div className="details">
                        <div className="category1">Ralph Edwards</div>
                        <div className="category2">{`Harvesting 32KWh `}</div>
                      </div>
                    </div>
                  </div>
                  <div className="buttons-group1">
                    <div className="button">
                      <img
                        className="icon-jam-icons-outline-l8"
                        alt=""
                        src="/icon--jamicons--outline--logos--plus.svg"
                      />
                      <div className="text-container4">
                        <div className="button-text4">Button Text</div>
                      </div>
                      <img
                        className="icon-jam-icons-outline-l9"
                        alt=""
                        src="/icon--jamicons--outline--logos--arrowright-3.svg"
                      />
                    </div>
                    <div className="button1">
                      <img
                        className="icon-jam-icons-outline-l10"
                        alt=""
                        src="/icon--jamicons--outline--logos--plus.svg"
                      />
                      <div className="text-container5">
                        <div className="button-text5">Button Text</div>
                      </div>
                      <img
                        className="icon-jam-icons-outline-l11"
                        alt=""
                        src="/icon--jamicons--outline--logos--arrowright-4.svg"
                      />
                    </div>
                  </div>
                  <div className="badge2">
                    <img
                      className="icon-jam-icons-outline-l12"
                      alt=""
                      src="/icon--jamicons--outline--logos--circle.svg"
                    />
                    <div className="text-container6">
                      <div className="button-text6">Featured</div>
                    </div>
                    <img
                      className="icon-iconoir-cancel"
                      alt=""
                      src="/icon--iconoir--cancel.svg"
                    />
                  </div>
                  <img
                    className="user-thumb-icon1"
                    alt=""
                    src="/user-thumb@2x.png"
                  />
                </div>
                <div className="content-box1">
                  <img className="picture-icon2" alt="" src="/picture@2x.png" />
                  <div className="content1">
                    <div className="icon1">
                      <img
                        className="icon-iconoir-trophy"
                        loading="eager"
                        alt=""
                        src="/icon--iconoir--trophy@2x.png"
                      />
                    </div>
                    <div className="title-category2">
                      <div className="category3"></div>
                      <div className="title-container1">
                        <h3 className="title1">
                          <p className="simplified-investment-selectio">
                            Simplified investment selections
                          </p>
                        </h3>
                        <div className="badge3">
                          <img
                            className="icon-jam-icons-outline-l13"
                            alt=""
                            src="/icon--jamicons--outline--logos--circle.svg"
                          />
                          <div className="text-container7">
                            <div className="button-text7">+2,5%</div>
                          </div>
                          <img
                            className="icon-iconoir-stat-up1"
                            alt=""
                            src="/icon--iconoir--statup.svg"
                          />
                        </div>
                        <img
                          className="icon-jam-icons-outline-l14"
                          alt=""
                          src="/icon--jamicons--outline--logos--pencil.svg"
                        />
                      </div>
                    </div>
                    <img
                      className="picture-icon3"
                      alt=""
                      src="/picture@2x.png"
                    />
                    <div className="paragraph22">
                      Depending on your level of comfort, you can have the
                      platform choose investments for you, get help choosing, or
                      choose yourself.
                    </div>
                    <div className="user-card1">
                      <img
                        className="user-thumb-icon2"
                        alt=""
                        src="/user-thumb-1@2x.png"
                      />
                      <div className="details1">
                        <div className="category4">Ralph Edwards</div>
                        <div className="category5">{`Harvesting 32KWh `}</div>
                      </div>
                    </div>
                  </div>
                  <div className="buttons-group2">
                    <div className="button2">
                      <img
                        className="icon-jam-icons-outline-l15"
                        alt=""
                        src="/icon--jamicons--outline--logos--plus.svg"
                      />
                      <div className="text-container8">
                        <div className="button-text8">Button Text</div>
                      </div>
                      <img
                        className="icon-jam-icons-outline-l16"
                        alt=""
                        src="/icon--jamicons--outline--logos--arrowright-3.svg"
                      />
                    </div>
                    <div className="button3">
                      <img
                        className="icon-jam-icons-outline-l17"
                        alt=""
                        src="/icon--jamicons--outline--logos--plus.svg"
                      />
                      <div className="text-container9">
                        <div className="button-text9">Button Text</div>
                      </div>
                      <img
                        className="icon-jam-icons-outline-l18"
                        alt=""
                        src="/icon--jamicons--outline--logos--arrowright-4.svg"
                      />
                    </div>
                  </div>
                  <div className="badge4">
                    <img
                      className="icon-jam-icons-outline-l19"
                      alt=""
                      src="/icon--jamicons--outline--logos--circle.svg"
                    />
                    <div className="text-container10">
                      <div className="button-text10">Featured</div>
                    </div>
                    <img
                      className="icon-iconoir-cancel1"
                      alt=""
                      src="/icon--iconoir--cancel.svg"
                    />
                  </div>
                  <img
                    className="user-thumb-icon3"
                    alt=""
                    src="/user-thumb@2x.png"
                  />
                </div>
              </div>
              <div className="row1">
                <div className="content-box2">
                  <img className="picture-icon4" alt="" src="/picture@2x.png" />
                  <div className="content2">
                    <div className="icon2">
                      <img
                        className="icon-iconoir-trekking"
                        loading="eager"
                        alt=""
                        src="/icon--iconoir--trekking@2x.png"
                      />
                    </div>
                    <div className="title-category3">
                      <div className="category6"></div>
                      <div className="title-container2">
                        <h3 className="title2">Modern experience</h3>
                        <div className="badge5">
                          <img
                            className="icon-jam-icons-outline-l20"
                            alt=""
                            src="/icon--jamicons--outline--logos--circle.svg"
                          />
                          <div className="text-container11">
                            <div className="button-text11">+2,5%</div>
                          </div>
                          <img
                            className="icon-iconoir-stat-up2"
                            alt=""
                            src="/icon--iconoir--statup.svg"
                          />
                        </div>
                        <img
                          className="icon-jam-icons-outline-l21"
                          alt=""
                          src="/icon--jamicons--outline--logos--pencil.svg"
                        />
                      </div>
                    </div>
                    <img
                      className="picture-icon5"
                      alt=""
                      src="/picture@2x.png"
                    />
                    <div className="paragraph3">
                      Our intuitive and jargon-free portal simplifies the way
                      you engage with your 401(k) retirement plan.
                    </div>
                    <div className="user-card2">
                      <img
                        className="user-thumb-icon4"
                        alt=""
                        src="/user-thumb@2x.png"
                      />
                      <div className="details2">
                        <div className="category7">Ralph Edwards</div>
                        <div className="category8">{`Harvesting 32KWh `}</div>
                      </div>
                    </div>
                  </div>
                  <div className="buttons-group3">
                    <div className="button4">
                      <img
                        className="icon-jam-icons-outline-l22"
                        alt=""
                        src="/icon--jamicons--outline--logos--plus.svg"
                      />
                      <div className="text-container12">
                        <div className="button-text12">Button Text</div>
                      </div>
                      <img
                        className="icon-jam-icons-outline-l23"
                        alt=""
                        src="/icon--jamicons--outline--logos--arrowright-3.svg"
                      />
                    </div>
                    <div className="button5">
                      <img
                        className="icon-jam-icons-outline-l24"
                        alt=""
                        src="/icon--jamicons--outline--logos--plus.svg"
                      />
                      <div className="text-container13">
                        <div className="button-text13">Button Text</div>
                      </div>
                      <img
                        className="icon-jam-icons-outline-l25"
                        alt=""
                        src="/icon--jamicons--outline--logos--arrowright-4.svg"
                      />
                    </div>
                  </div>
                  <div className="badge6">
                    <img
                      className="icon-jam-icons-outline-l26"
                      alt=""
                      src="/icon--jamicons--outline--logos--circle.svg"
                    />
                    <div className="text-container14">
                      <div className="button-text14">Featured</div>
                    </div>
                    <img
                      className="icon-iconoir-cancel2"
                      alt=""
                      src="/icon--iconoir--cancel.svg"
                    />
                  </div>
                  <img
                    className="user-thumb-icon5"
                    alt=""
                    src="/user-thumb@2x.png"
                  />
                </div>
                <div className="content-box3">
                  <img className="picture-icon6" alt="" src="/picture@2x.png" />
                  <div className="content3">
                    <div className="icon3">
                      <img
                        className="icon-iconoir-piggy-bank"
                        loading="eager"
                        alt=""
                        src="/icon--iconoir--piggybank@2x.png"
                      />
                    </div>
                    <div className="title-category4">
                      <div className="category9"></div>
                      <div className="title-container3">
                        <h3 className="title3">Human support</h3>
                        <div className="badge7">
                          <img
                            className="icon-jam-icons-outline-l27"
                            alt=""
                            src="/icon--jamicons--outline--logos--circle.svg"
                          />
                          <div className="text-container15">
                            <div className="button-text15">+2,5%</div>
                          </div>
                          <img
                            className="icon-iconoir-stat-up3"
                            alt=""
                            src="/icon--iconoir--statup.svg"
                          />
                        </div>
                        <img
                          className="icon-jam-icons-outline-l28"
                          alt=""
                          src="/icon--jamicons--outline--logos--pencil.svg"
                        />
                      </div>
                    </div>
                    <img
                      className="picture-icon7"
                      alt=""
                      src="/picture@2x.png"
                    />
                    <div className="paragraph4">
                      In addition to our digital help center and extensive
                      educational resources, we have a team of dedicated
                      professionals here to help along the way.
                    </div>
                    <div className="user-card3">
                      <img
                        className="user-thumb-icon6"
                        alt=""
                        src="/user-thumb-1@2x.png"
                      />
                      <div className="details3">
                        <div className="category10">Ralph Edwards</div>
                        <div className="category11">{`Harvesting 32KWh `}</div>
                      </div>
                    </div>
                  </div>
                  <div className="buttons-group4">
                    <div className="button6">
                      <img
                        className="icon-jam-icons-outline-l29"
                        alt=""
                        src="/icon--jamicons--outline--logos--plus.svg"
                      />
                      <div className="text-container16">
                        <div className="button-text16">Button Text</div>
                      </div>
                      <img
                        className="icon-jam-icons-outline-l30"
                        alt=""
                        src="/icon--jamicons--outline--logos--arrowright-3.svg"
                      />
                    </div>
                    <div className="button7">
                      <img
                        className="icon-jam-icons-outline-l31"
                        alt=""
                        src="/icon--jamicons--outline--logos--plus.svg"
                      />
                      <div className="text-container17">
                        <div className="button-text17">Button Text</div>
                      </div>
                      <img
                        className="icon-jam-icons-outline-l32"
                        alt=""
                        src="/icon--jamicons--outline--logos--arrowright-4.svg"
                      />
                    </div>
                  </div>
                  <div className="badge8">
                    <img
                      className="icon-jam-icons-outline-l33"
                      alt=""
                      src="/icon--jamicons--outline--logos--circle.svg"
                    />
                    <div className="text-container18">
                      <div className="button-text18">Featured</div>
                    </div>
                    <img
                      className="icon-iconoir-cancel3"
                      alt=""
                      src="/icon--iconoir--cancel.svg"
                    />
                  </div>
                  <img
                    className="user-thumb-icon7"
                    alt=""
                    src="/user-thumb@2x.png"
                  />
                </div>
              </div>
              <img
                className="group-39813-2"
                alt=""
                src="/group-39813-2@2x.png"
              />
            </div>
          </div>
        </div>
      </section>
      {/* <section className="main-frame">
        <div className="footer-text">
          <div className="footer-text-child" />
          <div className="logo-frame1">
            <h1 className="already-saving-with">Already saving with us?</h1>
            <button className="secondary-headline1">
              <div className="about-us-text">
                <div className="log-in1">Log in</div>
              </div>
            </button>
          </div>
        </div>
        </section> */}
        <GainValueCompany />
      
    </div>
  );
};

export default KiotaCompanySavings;
