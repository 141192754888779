import "../styles/SaverCards.css"

const Layer = () => {
  return (
    <section className="frame-with-copyright-text">
      <div className="community-hub">
        <div className="membership-organizatios1">
          <img
            className="group-39813-22"
            alt=""
            src="/group-39813-2-1@2x.png"
          />
          <div className="group1">
            <div className="retirement-programs">
              <div className="vector-parent">
                <img
                  className="vector-icon2"
                  loading="eager"
                  alt=""
                  src="/6502978-1@2x.png"
                />
                <img
                  className="record-keeping-icon"
                  alt=""
                  src="/vector-1.svg"
                />
              </div>
            </div>
            <div className="clone">
              
            </div>
            <div className="membership-orgs">
              <h2 className="record-keeping">Record Keeping</h2>
              <div className="when-joining-the">
                Leverage our end-to-end retirement offering or license our
                record-keeping technology.
              </div>
            </div>
          </div>
        </div>
        <div className="membership-organizatios2">
          <img
            className="tiny-students-sitting-near-boo"
            loading="eager"
            alt=""
            src="/tiny-students-sitting-near-books-getting-university-degree-1@2x.png"
          />
          <div className="retirement-programs-parent">
            <h2 className="retirement-programs1">Retirement Programs</h2>
            <div className="when-joining-the1">
              From roboadvisor managed accounts to financial wellness to
              Multiple or Pooled Employer Plans (MEPs and PEPs), put your
              expertise front and center.
            </div>
          </div>
          <img
            className="group-39813-23"
            alt=""
            src="/group-39813-2-1@2x.png"
          />
        </div>
        <div className="membership-organizatios3">
          <div className="i402042fm004c9-1-parent">
            <img
              className="i402042fm004c9-1-icon"
              loading="eager"
              alt=""
              src="/2210i402042fm004c9-1@2x.png"
            />
            <div className="tpas-parent">
              <h2 className="tpas1">TPAs</h2>
              <div className="when-joining-the2">
                Support plans with full administrative oversight.
              </div>
            </div>
          </div>
          <img
            className="group-39813-24"
            alt=""
            src="/group-39813-2-1@2x.png"
          />
        </div>
      </div>
      <img
        className="layer-1-icon"
        loading="eager"
        alt=""
        src="/layer-1@2x.png"
      />
    </section>
  );
};

export default Layer;
