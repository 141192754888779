import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruck, faTrophy } from '@fortawesome/free-solid-svg-icons';
import '../styles/Individual-savings.css';
import { Link } from 'react-router-dom';
import vector from "../assets/Vector.png";
import individual from "../assets/individual.png";
import individual1 from "../assets/individual1.png";
import individual2 from "../assets/individual2.png";
import individual3 from "../assets/individual3.png";
import services from "../assets/services.png";


const  Individual = () => {
    return (
        <div className="individual-savings"> 
        <div className="card">
            <div className="left-intro">
               <Link to=""><img src={vector} alt="values" /> </Link>
               <h1> Individual Savings</h1>
               <p>Saving for retirement is important. We're here to help.</p>
               <div className="login-container">
               <Link to="/loginPage"><button >Login</button></Link>
               </div>
            </div>
                <div className="right-intro">
                <Link to=""><img src={individual} alt="individualimage" /> </Link>
                </div>
        </div> 

            <div>
                <section className="display-images">
                    <div className="image-1">
                    <Link to=""><img src={individual1} alt="individualimage" /></Link>
                    <h2>Tools for investing <br></br>and Saving</h2>
                    </div>
                    <div className="image-2">
                    <Link to=""><img src={individual2} alt="individualimage1" /></Link>
                    <h3>Expert insights, resources, and education</h3>
                    </div>
                    <div className="image-2">
                    <Link to=""><img src={individual3} alt="individualimage2" /></Link>
                    <h4>Individual Retirement Accounts (IRAs)</h4>
                    </div>
                </section>
            </div>
             <div>
                <section className="benefits-of-retirement">
                    <h1>We're committed to giving high yields amid low transaction  prices.</h1>
                </section>
             </div>
             <div className="bottom-images">
                <div className="bottom-left">
                <div className="how-much-to-save">
        <FontAwesomeIcon icon={faTruck} className="trucck"/> 
        <h1>Decide How Much to Save</h1>
        <h2>With completely digital enrollment <br></br> and deferral selection, <br></br> we make it easy for you <br></br>to hit the ground saving.</h2>
      </div>

      <div className="select-your-investment-strategy">
        <FontAwesomeIcon icon={faTrophy}className="trophy" /> 
        <h1>Select your investment strategy</h1>
        <h2>Depending on your level of comfort <br></br>you can have the platform choose investments <br></br> for you get help choosing, or choose yourself.</h2>
      </div>

      <div className="pick-a-beneficiary">
        <FontAwesomeIcon icon={faTrophy} className="trophy" />
        <h1>Pick a beneficiary</h1>
        <h2>Depending on your level of comfort <br></br> you can have the platform choose <br></br>investments  for you  get help choosing <br></br>or choose yourself.</h2>
      </div>
        </div>
               
             </div>
        </div>

    
    );
};
export default Individual;