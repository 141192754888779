import React from 'react';
import { NavLink } from 'react-router-dom'; // Import NavLink
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import '../styles/Banner.css';

const Banner = () => {
 return (
    <nav className="banner">
      <div className="left-content">
        <p> We are Ready to Serve you 24/7 </p>
      </div>
      
      <div className="right-content">
        <p> 
          <NavLink to="/Login" activeClassName="active">Individual</NavLink> | 
          <NavLink to="/Login" activeClassName="active">Company</NavLink> | 
          <NavLink to="/Login" activeClassName="active">TPA</NavLink>
        </p>
        <a href="https://wa.me/254795824834" target="_blank" rel="noopener noreferrer" className="chat">
          <FontAwesomeIcon icon={faPhone} />
          <span> Chat with My Kiota</span>
        </a>
      </div>
    </nav>
 );
};

export default Banner;