import React from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faFilm, faFolder, faGear, faHome } from '@fortawesome/free-solid-svg-icons';
import Logo from '../assets/logo.png';
import UserProfile from "./UserProfile";
import '../styles/Dashboardnavbar.css';

const DashboardEmployer = () => {
   return (
      <div className="dashboard">
         <div className="horizontal_navbar">
            <NavLink to="/"><img src={Logo} alt="Logo" /></NavLink>
            <input type="search" placeholder="Search" className="search" />   
            <UserProfile/>
         </div>
         <div className="vertical_navbar">
            <div className="nav">
               <NavLink to="/Home"><FontAwesomeIcon icon={faHome} className="dash-icons" /> Home</NavLink>
               <NavLink to="/Contributions"><FontAwesomeIcon icon={faFile} className="dash-icons"/>Contributions</NavLink>
               <NavLink to="/Employee"><FontAwesomeIcon icon={faFilm} className="dash-icons" /> Employee</NavLink>
               <NavLink to="/Company"><FontAwesomeIcon icon={faFolder} className="dash-icons" /> Company</NavLink>
               <NavLink to="/more"><FontAwesomeIcon icon={faGear} className="dash-icons" />        More</NavLink>
              
            </div>
         </div>
         <div className="content-container">
            <div className="content">
                <h1> Employee Company ltd</h1>
            </div>
         </div>
      </div>
   );
};

export default DashboardEmployer;
