
import React, { useState } from "react";
import "../styles/kiotacalculator.css";
import Logo from '../assets/logo.png';
import { Link } from 'react-router-dom';
import { FaCalculator } from "react-icons/fa";
import { CiCalculator2 } from "react-icons/ci";
import { FcCalculator } from "react-icons/fc";


const useInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);
  const handleChange = (e) => {
    setValue(e.target.value);
  };
  return [value, handleChange];
};


const Calculator = () => {
  
  const [currentAge, handleCurrentAge] = useInput("");
  const [retirementAge, handleRetirementAge] = useInput("");
  const [monthlySavings, handleMonthlySavings] = useInput("");
  const [annualReturn, handleAnnualReturn] = useInput("");

  
  const [result, setResult] = useState(0);

 
  const calculate = () => {
    
    const age = Number(currentAge);
    const retire = Number(retirementAge);
    const savings = Number(monthlySavings);
    const rate = Number(annualReturn) / 100;

    
    if (
      age > 0 &&
      retire > age &&
      savings > 0 &&
      rate >= 0 &&
      rate <= 1
    ) {
   
      const years = retire - age;
      const months = years * 12;

    
      const futureValue =
        savings *
        (((1 + rate / 12) ** months - 1) / (rate / 12)) *
        (1 + rate / 12);

     
      const roundedValue = Math.round(futureValue * 100) / 100;

   
      setResult(roundedValue);
    } else {
     
      setResult(0);
    }
  };

  return (

    <div className="calculator">
      {/* <div className="calculator-brand">
            <Link to="/">
              <img src={Logo} alt="Logo" />
            </Link>
          </div>
      <div classname= "story-paragraph">
        <h1>Write your story with<br></br><FcCalculator className="calculator-icon1"/> </h1>
      <p>Your retirement is on the horizon, but how far away? You can use this calculator to help you see where you stand in relation to your retirement goal and map out different paths to reach your target. You can print the results for future reference.</p>
      <h1>Your retirement story</h1>
      <p>I'm 
21
 years old and I plan to retire at age 
67
. I make 
$35,000
 a year and save 
$0
 Or
0.0%
 of my income annually for retirement.I've already saved 
$0
 for retirement and I think I'll need 
85.0%
  of my current income in retirement. I expect an annual return of 
5.0%
from my retirement savings.</p>
      </div>
      <div classname="story-title2">
        <h1> How much will I have left in retirement?</h1>
      </div>
      <div classname="story-paragraph2">
        <p>I'm 
            21
           years old and I plan to retire at age 
           67
           . I make 
           $35,000
           a year and save 
            $0
           Or
            0.0%
           of my income annually for retirement.I've already saved 
            $0
           for retirement and I think I'll need 
            85.0%
            of my current income in retirement. I expect an annual return of 
            5.0%
            from my retirement savings.</p>
      </div> */}
      
      <h1>Retirement Calculator</h1>
      <div className="input-group">
        
        <label className="current-age">What is your current age?:</label>
        <input
          type="number"
          id="current-age"
          value={currentAge}
          onChange={handleCurrentAge}
        />
      </div>
      <div className="input-group">
        <label className="retirement-age">When would you like to retire?:</label>
        <input
          type="number"
          id="retirement-age"
          value={retirementAge}
          onChange={handleRetirementAge}
        />
      </div>
      <div className="input-group">
        <label className="monthly-savings">Your Monthly Savings (KES):</label>
        <input
          type="number"
          id="monthly-savings"
          value={monthlySavings}
          onChange={handleMonthlySavings}
        />
      </div>
      <div className="input-group">
        <label className="annual-return">Expected Annual Return (%):</label>
        <input
          type="number"
          id="annual-return"
          value={annualReturn}
          onChange={handleAnnualReturn}
        />
      </div>
      <button className="calculate-btn" onClick={calculate}>Calculate</button>
      <div className="result">
        <p>
          Your retirement savings will be <strong>{result}</strong> KES.
        </p>
      </div>
    
      
    </div>
    
  );
};


export default Calculator;