// WaitList.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';

import '../styles/WaitList.css';

const WaitList = ({ handleSubmit }) => {
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false); // Initialize isLoading state
  const navigate = useNavigate(); 


  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  }

  const handleFormSubmit = (e) => {
    e.preventDefault();

    setIsLoading(true);

    // Directly making a POST request to the specified URL
    fetch('https://v1-kiotaapis.onrender.com/saverwaitlist', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
    .then(response => response.json())
    .then(data => {
      console.log('Success:', data);
      setIsLoading(false); // Hide loading overlay
      if (handleSubmit) {
        handleSubmit(e, formData);
      }
     navigate('/success'); // Redirect to Success page
    })
    .catch((error) => {
      console.error('Error:', error);
      setIsLoading(false); // Hide loading overlay
    });
  }

  return (
    <div className="registration-page">
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-container">
            <ClipLoader color="#36d7b7" size={50} />
            <p>Processing your request...</p>
          </div>
        </div>
      )}
      <div className="reg-card">
        <div className="logo-container">
          <img src="kiota-logo-1.svg" alt="Logo" id="logo" />
        </div>

        <form onSubmit={handleFormSubmit}>
          <div className="input-group">
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name || ''}
              onChange={handleChange}
              required
              placeholder='Enter your name'
              autoComplete='off'
              class="text-input"
            />
            <label htmlFor="name" class="label">Name</label>
          </div>

          <div className="input-group">
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email || ''}
              onChange={handleChange}
              required
              placeholder='Enter your email'
              class="text-inputt"
            />
            <label htmlFor="email" class="labell">Email</label>
          </div>

          <div className="input-group">
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone || ''}
              onChange={handleChange}
              required
              placeholder='Enter your phone number'
              class="text-inputtt"
            />
            <label htmlFor="phone" class="labelll">PhoneNumber</label>
          </div>

          <button type="submit" className="register-btn">
            Join the WaitList
          </button>
        </form>
      </div>

      <div className="copyright">
        Copyright &copy; 2024 Kiota, All rights reserved.
      </div>
    </div>
  );
}

export default WaitList;