import FrameComponent9 from "../components/FrameComponent9";
import FrameComponent8 from "../components/FrameComponent8";
import GainValue from "../components/GainValue";
import "../styles/KiotaTPAs.css";


const KiotaTPAs = () => {
  return (
    <div className="kiota-tpas">
      <div className="rectangle-parent4">
        <div className="frame-child7" />
        <div className="frame-parent11">
          <div className="frame-parent12">
            <div className="putting-individuals-on-the-pat-parent2">
              <div className="empowering-the-workforce4">
                Empowering the workforce to take control of their financial
                well-being with tools to Pay, Save, Borrow, and Protect
              </div>
            </div>
            <div className="request-a-demo-wrapper2">
              <div className="request-a-demo5">Request a demo</div>
            </div>
          </div>
          <img className="image-icon4" alt="" src="/image.svg" />
        </div>
      </div>
     
      <section className="frame-section">
        <FrameComponent9 />
        <div className="frame-parent14">
          <FrameComponent8 />
          <div className="footer-big">
            <div className="wrapper-asset">
              <img
                className="asset-icon"
                loading="eager"
                alt=""
                src="/frame-351.svg"
              />
            </div>
          </div>
        </div>
      </section>
      <GainValue />
    </div>
  );
};

export default KiotaTPAs;
