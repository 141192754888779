import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaLinkedin, FaTiktok, FaFacebookF} from 'react-icons/fa';
import { IoLogoYoutube } from "react-icons/io";
import { FaXTwitter, FaYoutube } from "react-icons/fa6";
import { faTiktok, faLinkedin, faXTwitter, faFacebookF } from '@fortawesome/free-brands-svg-icons';
import '../styles/Footer.css';

const Footer = () => {
  const [isMobile, setIsMobile] = useState(false);
 
  useEffect(() => {
     const handleResize = () => {
       setIsMobile(window.innerWidth <= 768); // Assuming 768px is the breakpoint for mobile devices
     };
 
     window.addEventListener('resize', handleResize);
     handleResize(); // Call once on mount to set initial state
 
     return () => {
       window.removeEventListener('resize', handleResize);
     };
  }, []);
 
  return (
    <footer className="footer-big-4">
      {isMobile || !isMobile  ? (
        <>
          <img className="asset-4-1" alt="" src="/asset-4-1@2x.png" />
          <div className="copyright-info-frame">
            <div className="footer-copyright-frame">
              <img className="kiota-logo-11" alt="" src="/kiota-logo-1.svg" />
              <div className="kiotalogonode">
                {/* <div classname="aboutkiota">About Kiota</div> */}
                <div className="copyright-2023">Copyright © 2024, Mykiota Solutions Limited. All Rights Reserved</div>
                {/* <div className="all-rights-reserved">All rights reserved</div> */}
              </div>
            </div>
            <div className="social-links">
            <a href="https://www.facebook.com" target="_blank">
                {/* <FontAwesomeIcon icon={faFacebookF} className="social-icons3" /> */}
                <FaFacebookF className="social-icons3" />
              </a>
              <a href="https://www.tiktok.com/@kiotachangu" target="_blank">
                {/* <FontAwesomeIcon icon={faTiktok} className="social-icons" /> */}
                <FaTiktok className="social-icons" />
              </a>
            <a href="https://www.linkedin.com/company/kiotachangu/" target="_blank">
                {/* <FontAwesomeIcon icon={faLinkedin} className="social-icons1" /> */}
                <FaLinkedin className="social-icons1" />
              </a>
              
              <a href="https://x.com/mykiota1?t=BxMOt_jgJ3tI1iqZu8y-Ww&s=09" target="_blank">
                {/* <FontAwesomeIcon icon={faXTwitter} className="social-icons2" /> */}
                <FaXTwitter className="social-icons2" />
              </a>
              <a href="https://www.youtube.com"  target="_blank">
                <IoLogoYoutube className="social-icons4"/>
                </a>
              
              
            </div>
          </div>
        </>
      ) : null}
      <div className="links">
        <div className="col-1">
        <div className="col-1">
            <h3 className="company">Company</h3>
            <div className="list-items">
              <Link to="/company" className="about-us1">Company</Link> {/* Updated */}
              <Link to="/about" className="who-we-power1">About us</Link> {/* Updated */}
              <Link to="/about" className="what-we-offer1">Who we Power</Link> {/* Updated */}
              <Link to="/about" className="learn1">What we Offer</Link> {/* Updated */}
              <Link to="/learn" className="learn1">Learn</Link> {/* Updated */}
            </div>
          </div>
        </div>
        <div className="col-2">
        <div className="col-11">
            <h3 className="company">Company</h3>
            <div className="list-items">
              <Link to="/company" className="about-us1">Company</Link> {/* Updated */}
              <Link to="/about" className="who-we-power1">About us</Link> {/* Updated */}
              <Link to="/about" className="what-we-offer1">Who we Power</Link> {/* Updated */}
              <Link to="/about" className="learn1">What we Offer</Link> {/* Updated */}
              <Link to="/learn" className="learn1">Learn</Link> {/* Updated */}
            </div>
          </div>
          <h3 className="support">Support</h3>
          <div className="list-items1">
            <div className="mykiota">MyKiota</div>
            <div className="sales-salesmykiotacom">
              <a
                className="salesmykiotacom"
                href="mailto:sales@mykiota.com"
                target="_blank"
              >
                info@mykiota.com
              </a>
            </div>
            <div className="general-254-795-824834">
              General: (254) 795-824834
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="stay-up-to">Stay up to date</div>
          <div className="email-input-field">
            <div className="input-field-bg" />
            <img
              className="essential-icons-send"
              alt=""
              src="/essential-icons--send.svg"
            />
            <div className="your-email-address">Your email address</div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
