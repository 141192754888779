// import "antd/dist/antd.min.css";
import { Input, Button } from "antd";
import "../styles/kiota-demo.css"
import { Link } from "react-router-dom";

const KiotaDemo = () => {
  return (
    <div className="demo-navigate">
      <div className="kiota-demo">
      <div className="kiota-demo-child" />
      <div className="rectangle-parent">
        <div className="frame-child" />
        <div className="frame-parent">
          <div className="frame-group">
            <div className="putting-individuals-on-the-pat-parent">
              <div className="putting-individuals-on">
                Putting individuals on the path towards financial health
              </div>
              <div className="empowering-the-workforce">
                Empowering the workforce to take control of their financial
                well-being with tools to Pay, Save, Borrow, and Protect
              </div>
            </div>
            <div className="request-a-demo-wrapper">
              <div className="request-a-demo1">Request a demo</div>
              <img className="image-icon" alt="" src="/image.svg" />
            </div>
          </div>
          
        </div>
      </div>
      <div className="answer-a-few">
        Answer a few simple questions and our Help You Decide tool will tell you
        which IRA may be best for your needs.
      </div>
      <div className="you-are-in-great-company-parent">
        <h1 className="you-are-in">You are in great company</h1>
        <img
          className="undraw-online-media-re-r9qv-1-icon"
          alt=""
          src="/undraw-online-media-re-r9qv-1@2x.png"
        />
        <div className="kiota-powers-hundreds-container">
          <p className="kiota-powers-hundreds">
            Kiota powers hundreds of retirement plans, and we're just getting
            started. We'd love to show you how we could be a great fit for your
            business.
          </p>
          <ul className="flexible-solutions-whether-yo">
            <li className="flexible-solutions-whether">
              Flexible solutions, whether you're a financial advisor or small
              business owner
            </li>
            <li className="flexible-solutions-whether">
              Intuitive and easy-to-use interface makes using Kiota a breeze
            </li>

            <li className="flexible-solutions-whether">
            Built from the ground up with modern infrastructure
            </li>
          </ul>
        </div>
      </div>
      <div className="demo-container">
        <div className="your-demo-with-kiota-is-waitin-parent">
          <h1>Your demo with Kiota is waiting.</h1>
           <p>We'll connect you with a Kiota expert who can give you a tour of the
            platform and answer any questions.</p>
        </div>
        <div className="by-requesting-a">
          By requesting a demo you agree to Kiota's Privacy Policy, and you
          consent to receive marketing communications from Kiota.
        </div>
        <div className="registration-wrapper">
          <div className="frame-divv">
            <div className="frame-parent1">
              <Input
                className="frame-item"
                placeholder="First Name"
                style={{ width: "503px" }}
                bordered={true}
              />
              <Input
                className="frame-item"
                placeholder="Last Name"
                style={{ width: "503px" }}
                bordered={true}
              />
              <Input
                className="frame-item"
                placeholder="Email"
                style={{ width: "503px" }}
                bordered={true}
              />
              <Input
                className="frame-item"
                placeholder="+254"
                style={{ width: "503px" }}
                bordered={true}
              />
              <Input
                className="frame-item"
                placeholder="Company name"
                style={{ width: "503px" }}
                bordered={true}
              />
              <Input
                className="frame-item"
                placeholder="No. of Employees"
                style={{ width: "503px" }}
                bordered={true}
              />
             <Link to="/">
                <Button style={{ width: "503px" }} type="default">
                        Request Demo
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <h1 className="request-a-demo1">Request a demo</h1>
      <img className="vector-icon" alt="" src="/vector@2x.png" />

        <div className="currently-enrolled">
          <div className="card">
            <p>
            If you're currently enrolled in a Kiota plan and need assistance,
            please contact Support here. If you're an individual looking to enroll
          in a new retirement plan, please contact us here too.
            </p>
          
          </div>
        </div>
      
      <div className="kiota-logo-1-wrapper">
        <Link to="/"><img className="kiota-logo-1" alt="" src="/kiota-logo-1@2x.png" /></Link>
      </div>
    </div>
    </div>
    
  );
};

export default KiotaDemo;
