import React from 'react';
import '../styles/GetStarted.css';
import { Link } from 'react-router-dom';

const WriteYourRetirementStory = ({ to }) => {
  return (
    <div className="get-started">
    <Link to={to}>
      <button>Plan Your Retirement Story</button>
    </Link>
  </div>
  );
};

export default WriteYourRetirementStory;
