import React from "react";
import "../styles/Employee.css";

const Employee = () => {
    return (
        <div className="ClassName">
            <h1>Employee</h1>
        </div>
    );
};
export default Employee;
