import React from 'react';
import '../styles/UserProfile.css';


const UserProfile = ({ username, position, icon }) => {
  return (
    <div className="user-profile">
      <img src={icon} className="user-icon" />
      <div className="user-details">
        <h4>{username}</h4>
        <p>{position}</p>
      </div>
    </div>
  );
};

export default UserProfile;