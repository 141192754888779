import React from 'react'
import { BrowserRouter as Router, Routes, Route, Switch } from 'react-router-dom';
import Banner from './components/Banner';
import Homepage from './pages/Homepage';
import KiotaDemo from './pages/kiota-demo';
import ScheduleDemoButton from './components/ScheduleDemoButton';
import About from './pages/About';
import WhoWePower from './pages/WhoWePower';
import WhatWeOffer from './pages/WhatWeOffer';
import Learn from './pages/Learn';
import Company from './pages/Company';
import Saver from './pages/Saver';
import KiotaTPAs from './pages/KiotaTPAs';
import Individual from './pages/Individual';
import Employee from './pages/Employee';
import SaversOnboardingPage from './components/SaversOnboardingPage';
import Layout from './components/Layouts';
// import RegistrationPage from './components/RegistrationPage';
import "./global.css";
import Login from './components/Login';
import Success from './components/Success';
import Dashboardnavbar from './components/Dashboardnavbar';
import Kiotacalculator from './components/Kiotacalculator';
import RetirementStory from './pages/RetirementStory';
import WaitList from './components/WaitList';
import DashboardSaver from './components/DashboardSaver';
import DashboardEmployer from './components/DashboardEmployer';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout><Homepage /></Layout>} />
        <Route path="/Kiotacalculator" element={<Layout><Kiotacalculator /></Layout>} />
        <Route path="/WaitList" element={<WaitList />} />
        <Route path="/RetirementStory" element={<Layout><RetirementStory /> </Layout>} />
        {/* <Route path="/register" element={<RegistrationPage />} /> */}
        <Route path="/success" element={<Success />} />
        <Route path="/kiota-demo" element={<Layout excludeBannerNavbar={true}><KiotaDemo /></Layout>} />
        <Route path="/about" element={<Layout><About /></Layout>} />
        <Route path="/WhoWePower" element={<Layout><WhoWePower /></Layout>} />
        <Route path="/WhatWeOffer" element={<Layout><WhatWeOffer /></Layout>} />
        <Route path="/Learn" element={<Layout><Learn /></Layout>} />
        <Route path="/Login" element={<Layout excludeBannerNavbar={true} excludeFooter={true}><Login /></Layout>} />
        <Route path="/Company" element={<Layout><Company /></Layout>} />
        <Route path="/Saver" element={<Layout><Saver /></Layout>} />
        <Route path="/KiotaTPAs" element={<Layout><KiotaTPAs /></Layout>} />
        <Route path="/Individual" element={<Layout><Individual /></Layout>} />
        <Route path="/Employee" element={<Layout><Employee /></Layout>} />
        <Route path="/schedule-demo" element={<Layout excludeBannerNavbar={true}><ScheduleDemoButton /></Layout>}/>
        <Route path="/SaversOnboardingPage" element={<Layout><SaversOnboardingPage /></Layout>} />
        <Route path="/Dashboardnavbar" element={<Dashboardnavbar/>} />
        <Route path="/DashboardSaver" element={<DashboardSaver/>} />
        <Route path="/DashboardEmployer" element={<DashboardEmployer/>} />
   
      </Routes>
    </Router>
  );
}

export default App;
