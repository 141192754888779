import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../styles/SaversOnboardingPage.css"; // Ensure this path is correct for your CSS file
import pensionPlanData from "../components/algorithmdata.json" // Adjust the path as necessary
import { parsePensionPlanData, recommendPensionPlans } from "./pensionPlanRecommendations";

const SaversOnboardingPage = () => {
  const [questionsVisible, setQuestionsVisible] = useState(true);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedChoices, setSelectedChoices] = useState([]);
  const [recommendedPlans, setRecommendedPlans] = useState([]);

  const onboardingQuestions = [
    {
      id: 1,
      question: "How much are you looking to contribute monthly?",
      choices: [
        "0 - 400",
        "400 - 999",
         "1000 - 10000", 
         "10000+",
      ],
    },
    {
      id: 2,
      question: "What do you want from your investments?",
      choices: [
        "I want income now. Ok with a little up and down in investment, but not too much",
        "I don’t want to lose money, even if that means lower or no gains",
        "I want income now and some potential for growth. Ok with short-term volatility.",
        "I don’t need money soon. Looking for growth, so some volatility is okay",
        "I’m looking for maximum growth in the long run, and I expect volatility",
      ],
    },
    {
      id: 3,
      question: "How will you be sending the contributions?",
      choices: [
        "M-Pesa", "Bank", "M-Pesa & Bank",
      ],
    },
    {
      id: 4,
      question: "One year ago ago, you contributed 16,000.00 KES. How much gain or loss would be comfortable with?",
      choices: [
        "No loss", "just gains", "I'm comfortable with some losses", "I want guaranteed gains."
      ],
    },
    {
      id: 5,
      question: "At what rate are you looking for your investments to grow at?",
      choices: [
        "4%", "5%", "6%", "7%", "8%", "9%", "10%", ">10%"
      ],
    },
    {
      id: 6,
      question: "When are you planning to use your savings?",
      choices: [
        "Less than 1 year", "1 - 5 years", "5 - 10 years", "10+ years"
      ],
    },
    {
      id: 7,
      question: "If your household loses its source of income today how long can you pay your bills with your non-retirement savings?",
      choices: [
        "Not very long", "A short time", "a little more than a month.", "A little while, 2 -3 months.",  "For some time maybe 4 or 6 months.", "I will be ok for a while, at least 6 months"
      ],
    }
  ];

  useEffect(() => {
    const parsedData = parsePensionPlanData(pensionPlanData);
    const recommendations = recommendPensionPlans(selectedChoices, parsedData);
    setRecommendedPlans(recommendations);
  }, [selectedChoices]);

  const handleChoiceSelect = (index) => {
    const newChoices = [...selectedChoices];
    newChoices[currentQuestion] = onboardingQuestions[currentQuestion].choices[index];
    setSelectedChoices(newChoices);
  };

  const handleNextQuestion = () => {
    if (currentQuestion < onboardingQuestions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    }
  };

  const handleSubmit = () => {
    setQuestionsVisible(false);
  };

  return (
    <div className="savers-onboarding-page">
      <h1>Savers Onboarding</h1>
      {questionsVisible && (
        <>
          <p>Answer the following questions to personalize your experience:</p>
          <div className="card2">
            <h2>{onboardingQuestions[currentQuestion].question}</h2>
            <div className="choices">
              {onboardingQuestions[currentQuestion].choices.map((choice, index) => (
                <div key={index} className="choice-card" onClick={() => handleChoiceSelect(index)}>
                  <div className={`choice ${selectedChoices[currentQuestion] === choice ? "selected" : ""}`}>
                    {choice}
                  </div>
                </div>
              ))}
            </div>
            {currentQuestion < onboardingQuestions.length - 1 && (
              <button className="next-button" onClick={handleNextQuestion}>Next</button>
            )}
            {currentQuestion === onboardingQuestions.length - 1 && (
              <button className="finish-button" onClick={handleSubmit}>Finish Onboarding</button>
            )}
          </div>
        </>
      )}
      {!questionsVisible && (
        <>
          <h2>Recommended Pension Plans</h2>
          <ul>
          {recommendedPlans.map((plan, index) => (
  <li key={index}>{plan.name || 'Plan Name Not Available'} - More details...</li>
))}
          </ul>
          <Link to="/saver-dashboard">
            <button className="go-to-dashboard-button">Go to Saver Dashboard</button>
          </Link>
        </>
      )}
    </div>
  );
};

export default SaversOnboardingPage;
