import { useMemo } from "react";
import "../styles/Row.css";

const Row = ({
  picture,
  iconIconoirTrekking,
  iconJamIconsOutlineLogos,
  iconIconoirStatUp,
  iconJamIconsOutlineLogos1,
  picture1,
  userThumb,
  iconJamIconsOutlineLogos2,
  iconJamIconsOutlineLogos3,
  iconJamIconsOutlineLogos4,
  iconJamIconsOutlineLogos5,
  iconJamIconsOutlineLogos6,
  iconIconoirCancel,
  userThumb1,
  picture2,
  iconIconoirPiggyBank,
  iconJamIconsOutlineLogos7,
  iconIconoirStatUp1,
  iconJamIconsOutlineLogos8,
  picture3,
  userThumb2,
  iconJamIconsOutlineLogos9,
  iconJamIconsOutlineLogos10,
  iconJamIconsOutlineLogos11,
  iconJamIconsOutlineLogos12,
  iconJamIconsOutlineLogos13,
  iconIconoirCancel1,
  userThumb3,
  propGap,
  propMinWidth,
  propHeight,
  propWidth,
  propMinWidth1,
  propHeight1,
  propWidth1,
  iconLeft,
  text,
  iconRight,
}) => {
  const row1Style = useMemo(() => {
    return {
      gap: propGap,
    };
  }, [propGap]);

  const contentBox2Style = useMemo(() => {
    return {
      minWidth: propMinWidth,
    };
  }, [propMinWidth]);

  const iconIconoirTrekkingStyle = useMemo(() => {
    return {
      height: propHeight,
      width: propWidth,
    };
  }, [propHeight, propWidth]);

  const contentBox3Style = useMemo(() => {
    return {
      minWidth: propMinWidth1,
    };
  }, [propMinWidth1]);

  const iconIconoirPiggyBankStyle = useMemo(() => {
    return {
      height: propHeight1,
      width: propWidth1,
    };
  }, [propHeight1, propWidth1]);

  return (
    <div className="row1" style={row1Style}>
      <div className="content-box3" style={contentBox2Style}>
        <img className="picture-icon7" alt="" src={picture} />
        <div className="content3">
          <div className="icon5">
            <img
              className="icon-iconoir-trekking1"
              loading="eager"
              alt=""
              src={iconIconoirTrekking}
              style={iconIconoirTrekkingStyle}
            />
          </div>
          <div className="title-category3">
            <div className="category11">Category</div>
            <div className="title-container3">
              <h3 className="title3">Modern experience</h3>
              <div className="badge9">
                <img
                  className="icon-jam-icons-outline-l39"
                  alt=""
                  src={iconJamIconsOutlineLogos}
                />
                <div className="text-container21">
                  <div className="button-text21">+2,5%</div>
                </div>
                <img
                  className="icon-iconoir-stat-up3"
                  alt=""
                  src={iconIconoirStatUp}
                />
              </div>
              <img
                className="icon-jam-icons-outline-l40"
                alt=""
                src={iconJamIconsOutlineLogos1}
              />
            </div>
          </div>
          <img className="picture-icon8" alt="" src={picture1} />
          <div className="paragraph5">
            Our intuitive and jargon-free portal simplifies the way you engage
            with your 401(k) retirement plan.
          </div>
          <div className="user-card3">
            <img className="user-thumb-icon4" alt="" src={userThumb} />
            <div className="details4">
              <div className="category12">Ralph Edwards</div>
              <div className="category13">{`Harvesting 32KWh `}</div>
            </div>
          </div>
        </div>
        <div className="buttons-group5">
          <div className="button4">
            <img
              className="icon-jam-icons-outline-l41"
              alt=""
              src={iconJamIconsOutlineLogos2}
            />
            <div className="text-container22">
              <div className="button-text22">Button Text</div>
            </div>
            <img
              className="icon-jam-icons-outline-l42"
              alt=""
              src={iconJamIconsOutlineLogos3}
            />
          </div>
          <div className="button5">
            <img
              className="icon-jam-icons-outline-l43"
              alt=""
              src={iconJamIconsOutlineLogos4}
            />
            <div className="text-container23">
              <div className="button-text23">Button Text</div>
            </div>
            <img
              className="icon-jam-icons-outline-l44"
              alt=""
              src={iconJamIconsOutlineLogos5}
            />
          </div>
        </div>
        <div className="badge10">
          <img
            className="icon-jam-icons-outline-l45"
            alt=""
            src={iconJamIconsOutlineLogos6}
          />
          <div className="text-container24">
            <div className="button-text24">Featured</div>
          </div>
          <img
            className="icon-iconoir-cancel5"
            alt=""
            src={iconIconoirCancel}
          />
        </div>
        <img className="user-thumb-icon5" alt="" src={userThumb1} />
      </div>
      <div className="content-box4" style={contentBox3Style}>
        <img className="picture-icon9" alt="" src={picture2} />
        <div className="content4">
          <div className="icon6">
            <img
              className="icon-iconoir-piggy-bank1"
              loading="eager"
              alt=""
              src={iconIconoirPiggyBank}
              style={iconIconoirPiggyBankStyle}
            />
          </div>
          <div className="title-category4">
            <div className="category14">Category</div>
            <div className="title-container4">
              <h3 className="title4">Human support</h3>
              <div className="badge11">
                <img
                  className="icon-jam-icons-outline-l46"
                  alt=""
                  src={iconJamIconsOutlineLogos7}
                />
                <div className="text-container25">
                  <div className="button-text25">+2,5%</div>
                </div>
                <img
                  className="icon-iconoir-stat-up4"
                  alt=""
                  src={iconIconoirStatUp1}
                />
              </div>
              <img
                className="icon-jam-icons-outline-l47"
                alt=""
                src={iconJamIconsOutlineLogos8}
              />
            </div>
          </div>
          <img className="picture-icon10" alt="" src={picture3} />
          <div className="paragraph6">
            In addition to our digital help center and extensive educational
            resources, we have a team of dedicated professionals here to help
            along the way.
          </div>
          <div className="user-card4">
            <img className="user-thumb-icon6" alt="" src={userThumb2} />
            <div className="details5">
              <div className="category15">Ralph Edwards</div>
              <div className="category16">{`Harvesting 32KWh `}</div>
            </div>
          </div>
        </div>
        <div className="buttons-group6">
          <div className="button6">
            <img
              className="icon-jam-icons-outline-l48"
              alt=""
              src={iconJamIconsOutlineLogos9}
            />
            <div className="text-container26">
              <div className="button-text26">Button Text</div>
            </div>
            <img
              className="icon-jam-icons-outline-l49"
              alt=""
              src={iconJamIconsOutlineLogos10}
            />
          </div>
          <div className="button7">
            <img
              className="icon-jam-icons-outline-l50"
              alt=""
              src={iconJamIconsOutlineLogos11}
            />
            <div className="text-container27">
              <div className="button-text27">Button Text</div>
            </div>
            <img
              className="icon-jam-icons-outline-l51"
              alt=""
              src={iconJamIconsOutlineLogos12}
            />
          </div>
        </div>
        <div className="badge12">
          <img
            className="icon-jam-icons-outline-l52"
            alt=""
            src={iconJamIconsOutlineLogos13}
          />
          <div className="text-container28">
            <div className="button-text28">Featured</div>
          </div>
          <img
            className="icon-iconoir-cancel6"
            alt=""
            src={iconIconoirCancel1}
          />
        </div>
        <img className="user-thumb-icon7" alt="" src={userThumb3} />
      </div>
    </div>
  );
};

export default Row;
