 // pensionPlanRecommendations.js

export const parsePensionPlanData = (data) => {
  // Assuming the first entry in the data array contains the criteria keys
  const criteriaKeys = data[0];
  const pensionPlans = data.slice(1); // All entries except the first one are pension plans

  return pensionPlans.map(plan => {
    const parsedPlan = {};
    Object.keys(plan).forEach(key => {
      const criteriaKey = criteriaKeys[key]; // Get the corresponding criteria key
      if (criteriaKey) { // If the criteria key exists
        parsedPlan[criteriaKey] = plan[key];
      }
    });
    return parsedPlan;
  });
};
// Continue in pensionPlanRecommendations.js

export const recommendPensionPlans = (userAnswers, pensionPlans) => {
  return pensionPlans.filter(plan => {
    let matches = 0;

    // Ensure the plan properties you're checking against are defined and are arrays before calling includes
    if (Array.isArray(plan.monthlyContributions) && plan.monthlyContributions.includes(userAnswers[0])) matches++;
    if (Array.isArray(plan.pensionNeeds) && plan.pensionNeeds.some(need => userAnswers[1].includes(need))) matches++;
    if (Array.isArray(plan.remittance) && plan.remittance.includes(userAnswers[2])) matches++;
    if (Array.isArray(plan.principal) && plan.principal.includes(userAnswers[3])) matches++;
    if (Array.isArray(plan.returns) && plan.returns.includes(userAnswers[4])) matches++;
    if (Array.isArray(plan.moneyNeeds) && plan.moneyNeeds.includes(userAnswers[5])) matches++;

    return matches >= (userAnswers.length / 2);
  });
};