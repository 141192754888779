import React from 'react';
import '../styles/WhoWePower.css'; // Import your CSS file for styling

const WhoWePower = () => {
  return (
    <div className="who-we-power">
      <h1>Who We Power</h1>
      <p>
        Here, you can describe the types of entities or individuals your company powers or serves.
        Explain the impact, services, or benefits your organization offers to these entities.
        This could include businesses, individuals, industries, or any other relevant groups.
      </p>
      <p>
        Feel free to elaborate on your mission, values, and goals in empowering or supporting
        these entities. Use this space to showcase testimonials, success stories, or specific
        examples highlighting your organization's impact.
      </p>
      {/* Add more content, images, or sections as needed */}
    </div>
  );
};

export default WhoWePower;
