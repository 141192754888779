// Success.js
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import '../styles/Success.css'
import Logo from "../assets/logo.png";
import Vector from "../assets/Vector.png";

const Success = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const message = searchParams.get('message') || 'You are now in the waitlist!';

  return (
    <div className="success-page"> 
        {/* <div className="logo">
        <Link to="/">
          <img src={Logo} alt="Logo" />
        </Link>
      </div> */}
      <div className="success-content">
      {/* <div className="Logo">
        <Link to="/">
          <img src={Logo} alt="Logo" />
        </Link>
      </div>
      <div className="vector">
        <Link to="">
          <img src={Vector} alt="vector" />
        </Link>
      </div> */}
      <div>
        
        <h1 className="success-title">Welcome to  </h1>
        <div className="Logoo">
        <Link to="/">
          <img src={Logo} alt="Logo" />
        </Link>
      </div>
      <div className="vector">
        <Link to="">
          <img src={Vector} alt="vector" />
        </Link>
      </div>
      </div>
        {/* <p className="success-message">{message}</p> */}
        <div className="title1">
          <h1>Your Sign-Up Was Successful!</h1>
          </div>
        <p className="success-info">Thank you for joining the Kiota community! We're thrilled to have you on board.<br></br> Your journey with us starts now, and we're here to support you every step of the way.</p>
        <div className="title1">
          <h1>What Happens Next?</h1>
          </div>
          <p className="success-info"><strong>Check Your Email:</strong> A confirmation email has been sent to your inbox. Please verify your email address to activate your account.<br></br>
          <strong>Explore Your Dashboard:</strong> Dive into your personalized dashboard where you can access all the features and tools Kiota offers.<br></br><strong>Take a tour:</strong> Dive into our features and start exploring what Kiota has to offer.<br></br>
<strong>Stay Updated:</strong>  Keep an eye on your inbox for our welcome email, which includes tips on how to get the most out of your Kiota experience.<br></br><strong>Join the Conversation:</strong> Connect with fellow members of the Kiota community. Share ideas, ask questions, and grow together. </p>
        <div className="title1">
          <h1>Need Help?</h1>
          </div>
          <p className="success-info"> Our support team is always here to assist you.<br></br> Whether you have questions about your account, need help with a feature, or just want to provide feedback, feel free to reach out to us anytime.</p>
          <div className="title1">
          <h1>Your adventure begins Now</h1>
          </div>
          <p className="success-info"> We believe in the power of community and innovation.<br></br> With Kiota, you're not just a user; you're a valued member of a growing network of forward-thinkers.<br></br> Let's make great things happen together!<br></br>

Thank you for choosing Kiota. Let’s soar to new heights!

Warm regards,<br></br>

The Kiota Team</p>
        {/* <p className="success-info">
  In the meantime, feel free to explore our website or contact us if you have any questions.
  <Link to="https://mykiota.com" target="_blank" rel="noopener noreferrer"> Visit our website</Link>
</p> */}
      </div>
    </div>
  );
};

export default Success;