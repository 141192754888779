import React from 'react';
import '../styles/DialogBox.css';

const DialogBox = ({ message, onConfirm, onCancel, setShowDialog, setShowModal }) => {
    return (
      <div className="dialog-box-overlay">
        <div className="dialog-box">
          <div className="dialog-box-content">
            <p>{message}</p>
            <div className="dialog-box-buttons">
              <button onClick={() => onConfirm(setShowDialog, setShowModal)}>OK</button>
              <button onClick={() => onCancel(setShowDialog)}>Cancel</button>
            </div>
          </div>
        </div>
      </div>
    );
  };

export default DialogBox;