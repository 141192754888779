import { useMemo } from "react";
import IconIconoirTruck from "./IconIconoirTruck";
import "../styles/Row1.css";

const Row1 = ({
  picture,
  iconIconoirTruck,
  iconJamIconsOutlineLogos,
  iconIconoirStatUp,
  iconJamIconsOutlineLogos1,
  picture1,
  userThumb,
  iconJamIconsOutlineLogos2,
  iconJamIconsOutlineLogos3,
  iconJamIconsOutlineLogos4,
  iconJamIconsOutlineLogos5,
  iconJamIconsOutlineLogos6,
  iconIconoirCancel,
  userThumb1,
  picture2,
  iconIconoirTrophy,
  iconJamIconsOutlineLogos7,
  iconIconoirStatUp1,
  iconJamIconsOutlineLogos8,
  picture3,
  userThumb2,
  iconJamIconsOutlineLogos9,
  iconJamIconsOutlineLogos10,
  iconJamIconsOutlineLogos11,
  iconJamIconsOutlineLogos12,
  iconJamIconsOutlineLogos13,
  iconIconoirCancel1,
  userThumb3,
  propGap,
  propMinWidth,
  propHeight,
  propWidth,
  propMinWidth1,
  propPadding,
  propMinWidth2,
  propPadding1,
  propMinWidth3,
  propMinWidth4,
  propHeight1,
  propWidth1,
  propWidth2,
  propMinWidth5,
  propPadding2,
  propMinWidth6,
  propPadding3,
  propMinWidth7,
  iconLeft,
  text,
  iconRight,
}) => {
  const rowStyle = useMemo(() => {
    return {
      gap: propGap,
    };
  }, [propGap]);

  const contentBoxStyle = useMemo(() => {
    return {
      minWidth: propMinWidth,
    };
  }, [propMinWidth]);

  const iconIconoirTruckStyle = useMemo(() => {
    return {
      height: propHeight,
      width: propWidth,
    };
  }, [propHeight, propWidth]);

  const detailsStyle = useMemo(() => {
    return {
      minWidth: propMinWidth1,
    };
  }, [propMinWidth1]);

  const buttonStyle = useMemo(() => {
    return {
      padding: propPadding,
      minWidth: propMinWidth2,
    };
  }, [propPadding, propMinWidth2]);

  const button4Style = useMemo(() => {
    return {
      padding: propPadding1,
      minWidth: propMinWidth3,
    };
  }, [propPadding1, propMinWidth3]);

  const contentBox1Style = useMemo(() => {
    return {
      minWidth: propMinWidth4,
    };
  }, [propMinWidth4]);

  const iconIconoirTrophy1Style = useMemo(() => {
    return {
      height: propHeight1,
      width: propWidth1,
    };
  }, [propHeight1, propWidth1]);

  const titleStyle = useMemo(() => {
    return {
      width: propWidth2,
    };
  }, [propWidth2]);

  const details1Style = useMemo(() => {
    return {
      minWidth: propMinWidth5,
    };
  }, [propMinWidth5]);

  const button5Style = useMemo(() => {
    return {
      padding: propPadding2,
      minWidth: propMinWidth6,
    };
  }, [propPadding2, propMinWidth6]);

  const button6Style = useMemo(() => {
    return {
      padding: propPadding3,
      minWidth: propMinWidth7,
    };
  }, [propPadding3, propMinWidth7]);

  return (
    <div className="row" style={rowStyle}>
      <div className="content-box1" style={contentBoxStyle}>
        <img className="picture-icon3" alt="" src={picture} />
        <div className="content1">
          <div className="icon3">
            <IconIconoirTruck
              iconIconoirTruckIconIcono="/icon--iconoir--truck@2x.png"
              iconIconoirTruckWidth="4em"
              iconIconoirTruckHeight="4em"
              iconIconoirTruckPosition="relative"
            />
          </div>
          <div className="title-category1">
            <div className="category5">Category</div>
            <div className="title-container1">
              <h3 className="title1">
                {`Easy `}
                <span className="enrollment">ENROLLMENT</span>
              </h3>
              <div className="badge5">
                <img
                  className="icon-jam-icons-outline-l25"
                  alt=""
                  src={iconJamIconsOutlineLogos}
                />
                <div className="text-container13">
                  <div className="button-text13">+2,5%</div>
                </div>
                <img
                  className="icon-iconoir-stat-up1"
                  alt=""
                  src={iconIconoirStatUp}
                />
              </div>
              <img
                className="icon-jam-icons-outline-l26"
                alt=""
                src={iconJamIconsOutlineLogos1}
              />
            </div>
          </div>
          <img className="picture-icon4" alt="" src={picture1} />
          <div className="paragraph3">
            With completely digital enrollment and deferral selection, we make
            it easy for you to hit the ground saving.
          </div>
          <div className="user-card1">
            <img className="user-thumb-icon" alt="" src={userThumb} />
            <div className="details2" style={detailsStyle}>
              <div className="category6">Ralph Edwards</div>
              <div className="category7">{`Harvesting 32KWh `}</div>
            </div>
          </div>
        </div>
        <div className="buttons-group3">
          <div className="button" style={buttonStyle}>
            <img
              className="icon-jam-icons-outline-l27"
              alt=""
              src={iconJamIconsOutlineLogos2}
            />
            <div className="text-container14">
              <div className="button-text14">Button Text</div>
            </div>
            <img
              className="icon-jam-icons-outline-l28"
              alt=""
              src={iconJamIconsOutlineLogos3}
            />
          </div>
          <div className="button1" style={button4Style}>
            <img
              className="icon-jam-icons-outline-l29"
              alt=""
              src={iconJamIconsOutlineLogos4}
            />
            <div className="text-container15">
              <div className="button-text15">Button Text</div>
            </div>
            <img
              className="icon-jam-icons-outline-l30"
              alt=""
              src={iconJamIconsOutlineLogos5}
            />
          </div>
        </div>
        <div className="badge6">
          <img
            className="icon-jam-icons-outline-l31"
            alt=""
            src={iconJamIconsOutlineLogos6}
          />
          <div className="text-container16">
            <div className="button-text16">Featured</div>
          </div>
          <img
            className="icon-iconoir-cancel3"
            alt=""
            src={iconIconoirCancel}
          />
        </div>
        <img className="user-thumb-icon1" alt="" src={userThumb1} />
      </div>
      <div className="content-box2" style={contentBox1Style}>
        <img className="picture-icon5" alt="" src={picture2} />
        <div className="content2">
          <div className="icon4">
            <img
              className="icon-iconoir-trophy1"
              loading="eager"
              alt=""
              src={iconIconoirTrophy}
              style={iconIconoirTrophy1Style}
            />
          </div>
          <div className="title-category2">
            <div className="category8">Category</div>
            <div className="title-container2">
              <h3 className="title2" style={titleStyle}>
                <p className="simplified-investment-selectio">
                  Simplified investment selections
                </p>
              </h3>
              <div className="badge7">
                <img
                  className="icon-jam-icons-outline-l32"
                  alt=""
                  src={iconJamIconsOutlineLogos7}
                />
                <div className="text-container17">
                  <div className="button-text17">+2,5%</div>
                </div>
                <img
                  className="icon-iconoir-stat-up2"
                  alt=""
                  src={iconIconoirStatUp1}
                />
              </div>
              <img
                className="icon-jam-icons-outline-l33"
                alt=""
                src={iconJamIconsOutlineLogos8}
              />
            </div>
          </div>
          <img className="picture-icon6" alt="" src={picture3} />
          <div className="paragraph4">
            Depending on your level of comfort, you can have the platform choose
            investments for you, get help choosing, or choose yourself.
          </div>
          <div className="user-card2">
            <img className="user-thumb-icon2" alt="" src={userThumb2} />
            <div className="details3" style={details1Style}>
              <div className="category9">Ralph Edwards</div>
              <div className="category10">{`Harvesting 32KWh `}</div>
            </div>
          </div>
        </div>
        <div className="buttons-group4">
          <div className="button2" style={button5Style}>
            <img
              className="icon-jam-icons-outline-l34"
              alt=""
              src={iconJamIconsOutlineLogos9}
            />
            <div className="text-container18">
              <div className="button-text18">Button Text</div>
            </div>
            <img
              className="icon-jam-icons-outline-l35"
              alt=""
              src={iconJamIconsOutlineLogos10}
            />
          </div>
          <div className="button3" style={button6Style}>
            <img
              className="icon-jam-icons-outline-l36"
              alt=""
              src={iconJamIconsOutlineLogos11}
            />
            <div className="text-container19">
              <div className="button-text19">Button Text</div>
            </div>
            <img
              className="icon-jam-icons-outline-l37"
              alt=""
              src={iconJamIconsOutlineLogos12}
            />
          </div>
        </div>
        <div className="badge8">
          <img
            className="icon-jam-icons-outline-l38"
            alt=""
            src={iconJamIconsOutlineLogos13}
          />
          <div className="text-container20">
            <div className="button-text20">Featured</div>
          </div>
          <img
            className="icon-iconoir-cancel4"
            alt=""
            src={iconIconoirCancel1}
          />
        </div>
        <img className="user-thumb-icon3" alt="" src={userThumb3} />
      </div>
    </div>
  );
};

export default Row1;
