import React from 'react';
import { Link } from 'react-router-dom';
//import './ScheduleDemoButton.css';
import '../styles/ScheduleDemoButton.css';

const ScheduleDemoButton = () => {
  return (
    <Link to="/demo.js" className="schedule-demo-button">
      Schedule a Demo
    </Link>
  );
};

export default ScheduleDemoButton;
