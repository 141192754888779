import "../styles/GainValue.css";

const GainValue = () => {
  return (
    <section className="gain-value">
      <div className="book-a-demo1">
        <div className="book-a-demo-child" />
        <div className="text-block-kiota-tools">
          <h1 className="gain-value-through1">
            Gain value through Kiota savings tools
          </h1>
          <button className="footer-content">
            <div className="demo-btn">
              <div className="book-a-demo2">Book a demo</div>
            </div>
          </button>
        </div>
      </div>
      
    </section>
  );
};

export default GainValue;
