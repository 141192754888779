import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../assets/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import '../styles/Navbar.css';
import WriteYourRetirementStory from "../components/GetStarted";
const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-brand">
        <Link to="/">
          <img src={Logo} alt="Logo" />
        </Link>
      </div>
      <div className={`navbar-links ${isMenuOpen ? 'show' : ''}`}>
        <ul className="nav-links">
          <li><Link to="/about">About Us</Link></li>
          <li className="dropdown">
            <span className='services'>
              Who we Power <FontAwesomeIcon icon={faAngleDown} className={isMenuOpen ? 'open' : ''} />
            </span>
            <div className="dropdown-content">
              <Link to="/saver">Savers</Link>
              <Link to="/kiotaTPAs">TPA</Link>
              <Link to="/Company">WorkPlace</Link>
            </div>
          </li>
          <li><Link to="/whatweoffer">What we Offer</Link></li>
          <li><Link to="/learn">Learn</Link></li>
          <li className="login-mobile"><Link to="/login">Login</Link></li>
        </ul>
        <div className="get-startedd">
          <Link to="/WaitList">
            <button className="get-started-button">Join the Waitlist<br />for Kiota Saver App</button>
          </Link>
        </div>
      </div>
      <div className={`hamburger-menu ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
    </nav>
  );
};

export default Navbar;
