import "../styles/FrameComponent9.css";

const FrameComponent9 = () => {
  return (
    <div className="frame-parent33">
      <header className="frame-parent34">
        <div className="frame-wrapper9">
        </div>
      </header>
      <div className="rectangle-parent18">
        <div className="frame-child23" />
        <div className="frame-wrapper10">
          <div className="frame-parent35">
            <div className="vector-parent1">
              <img
                className="vector-icon5"
                loading="eager"
                alt=""
                src="/vector1.svg"
              />
              <h1 className="third-party-administrators">
                Third-party administrators
              </h1>
            </div>
            <div className="saving-for-retirement-is-impor-wrapper">
              <div className="saving-for-retirement">
                Saving for retirement is important. We're here to help.
              </div>
            </div>
            <button className="frame-wrapper11">
              <div className="frame-parent36">
                <div className="frame-child24" />
                <div className="schedule-a-demo3">Schedule a demo</div>
                <img
                  className="arrow-right-icon4"
                  alt=""
                  src="/arrowright.svg"
                />
              </div>
            </button>
          </div>
        </div>
        <img
          className="logo-frame-icon"
          loading="eager"
          alt=""
          src="/8432-1@2x.png"
        />
      </div>
    </div>
  );
};

export default FrameComponent9;
