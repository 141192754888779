import { useMemo } from "react";
import "../styles/IconIconoirTruck.css";

const IconIconoirTruck = ({
  iconIconoirTruckIconIcono,
  iconIconoirTruckWidth,
  iconIconoirTruckHeight,
  iconIconoirTruckPosition,
}) => {
  const iconIconoirTruckStyle = useMemo(() => {
    return {
      width: iconIconoirTruckWidth,
      height: iconIconoirTruckHeight,
      position: iconIconoirTruckPosition,
    };
  }, [iconIconoirTruckWidth, iconIconoirTruckHeight, iconIconoirTruckPosition]);

  return (
    <img
      className="icon-iconoir-truck"
      alt=""
      src={iconIconoirTruckIconIcono}
      style={iconIconoirTruckStyle}
    />
  );
};

export default IconIconoirTruck;
