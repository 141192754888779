 
import React, { useState, useEffect } from "react";
import "../styles/RetirementStory.css";
import Logo from "../assets/logo.png";
import Vector from "../assets/Vector.png";
import { Link } from 'react-router-dom';
import { Tooltip, ReferenceDot } from 'recharts';
import DialogBox from "../components/DialogBox";
import { ClipLoader } from 'react-spinners';
import {
  Customized,
  LineChart,
  Line,
  XAxis,
  YAxis,
  Legend,
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer
} from 'recharts';

const useInput = (initialValue, min, max) => {
  const [value, setValue] = useState(initialValue);
  const handleChange = (e) => {
    const inputValue = e.target.value;
    const numericValue = inputValue === '' ? '' : parseFloat(inputValue);

    if (numericValue === '' || (numericValue >= min && numericValue <= max)) {
      setValue(numericValue);
    }
  };
  return [value, handleChange];
};

const handleDialogConfirm = (setShowDialog, setShowModal) => {
  setShowDialog(false);
  setShowModal(true);
};

const handleDialogCancel = (setShowDialog) => {
  setShowDialog(false);
};

const Currentage = document.getElementById("Currentage");
const Retirementage = document.getElementById("Retirementage");
const Currentincome = document.getElementById("Currentincome");
const Monthlysavings = document.getElementById("Monthlysavings");
const Currentsavings = document.getElementById("Currentsavings");
const Retirementamount = document.getElementById("Retirementamount");
const Annualreturn = document.getElementById("Annualreturn");

  // Currentage.addEventListener("mouseover", function() {
  // this.value = "Hovering...";
  // });

  // Currentage.addEventListener("mouseout", function() {
  // this.value = "Hover over me!";
  // });

  // Currentage.addEventListener("click", function() {
  //   this.value = "Clicked!";
  // });
  



// const dashInput = () => {
//   const [isHovered, setIsHovered] = useState(false);
//   const [isClicked, setIsClicked] = useState(false);

//   const handleMouseEnter = () => {
//     setIsHovered(true);
//   };

//   const handleMouseLeave = () => {
//     setIsHovered(false);
//   };

//   const handleClick = () => {
//     setIsClicked(!isClicked);
//   };
// }

const RetirementStory = () => {
  const [currentAge, handleCurrentAge] = useInput("", 0, 120); // Assuming age range between 0 and 120
  const [retirementAge, handleRetirementAge] = useInput("", 0, 120); // Assuming age range between 0 and 120
  const [monthlySavings, handleMonthlySavings] = useInput("", 0, 1000000000); // Assuming monthly savings range up to 1 billion
  const [annualReturn, handleAnnualReturn] = useInput("", 1, 15); // Assuming annual return range between 1% and 15%
  const [currentIncome, handleCurrentIncome] = useInput("", 0, 1000000000); // Assuming income range up to 1 billion
  const [monthlySavingsPercentage, handleMonthlySavingsPercentage] = useInput("", 1, 100); // Assuming monthly savings percentage range between 1% and 100%
  const [currentSavings, handleCurrentSavings] = useInput("", 0, 1000000000000); // Assuming current savings range up to 1 trillion
  const [retirementAmount, handleRetirementAmount] = useInput("", 0, 1000000000000); // Assuming retirement amount range up to 1 trillion
  const [retirementIncomePercentage, setRetirementIncomePercentage] = useState(''); // Used useState instead of useInput
  const [error, setError] = useState("");

  const [netWorthToday, setNetWorthToday] = useState(parseInt(currentSavings) || 0);
  const [netWorthAtRetirement, setNetWorthAtRetirement] = useState(0);
  const [chartData, setChartData] = useState([
    { name: 'Today', netWorth: netWorthToday },
  ]);
  const [peakPoint, setPeakPoint] = useState(null);
  const [showForm, setShowForm] = useState(true);

  const [isLoading, setIsLoading] = useState(false);

  const [showInfo, setShowInfo] = useState(false);

  const handleClick = () => {
    setShowInfo(!showInfo);
  };


  useEffect(() => {
    setNetWorthToday(parseInt(currentSavings) || 0);
  }, [currentSavings]);

  // const handleWaitListSubmit = (e, formData) => {
  //   setWaitListFormData(formData); // Store the form data
  //   setShowModal(false); // Close the modal
  // };
  const handleWaitListSubmit = async (e, formData) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      // Prepare the data to be sent to the server
      const requestData = {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        retirementStoryResults: {
          netWorthToday,
          netWorthAtRetirement,
          chartData,
          peakPoint,
          // more relevant data from the retirement story results
        },
      };
      

      // Send a POST request to your server-side route
      const response = await fetch('https://v1-kiotaapis.onrender.com/waitlist', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });


      if (response.ok) {
        // Handle successful response
        console.log('WaitList entry created successfully');
        window.location.href = '/success';
      } else {
        // Handle error response
        console.error('Error creating WaitList entry');
      }
    } catch (error) {
      console.error('Error submitting WaitList entry:', error);
    } finally {
      setWaitListFormData(formData); // Store the form data
      setShowModal(false); // Close the modal/form
      setIsLoading(false);
      // Display the calculation results here, e.g.:
      console.log("Calculation results:", netWorthAtRetirement);
    }
  };

  // use effect for set net worth at retirement
  const [waitListFormData, setWaitListFormData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showDialog, setShowDialog] = useState(false);


  const handleRetirementIncomePercentage = (e) => {
    const value = e.target.value;
    if (value >= 0 && value <= 100) {
      setRetirementIncomePercentage(value);
    }
  };
  // The calculate Funtion(updated)
  const calculate = () => {
    // Convert the input values to numbers
    const age = Number(currentAge);
    const retire = Number(retirementAge);
    const savingsPercentage = Number(monthlySavingsPercentage) / 100; // Convert percentage to decimal
    const rate = Number(annualReturn) / 100; // Convert percentage to decimal
    const currentSavingsAmount = Number(currentSavings);
    // const retirementIncomePercentage = Number(retirementIncomePercentage) / 100;
    const income = Number(currentIncome);

    let retirementIncomePercentage;
  
    let newPeakPoint = null;
    // Check if the input values are valid
    if (
      age > 0 &&
      retire > age &&
      savingsPercentage >= 0.01 && // Minimum 1% monthly savings
      savingsPercentage <= 1 && // Maximum 100% monthly savings
      rate >= 0.01 && // Minimum 1% annual return
      rate <= 0.15 && // Maximum 15% annual return
      currentSavingsAmount >= 0 &&
      income > 0 

    ) {


      // Calculate the retirement amount using the new formula
      const retirementAmount =
        (retire - age) * savingsPercentage * income + currentSavingsAmount * (1 + rate);
  
      handleCalculateResult(retirementAmount, currentSavingsAmount, age, retire, rate);
    } else {
      // Set the result state to zero if the input values are invalid
      setNetWorthAtRetirement(0);
      setNetWorthToday(0);
  
      // Clear the chart data
      setChartData([]);
      setPeakPoint(null);

      //Err

    setError("Please enter valid input values.");
    }
  };
  
  const handleCalculateResult = (
    retirementAmount,
    currentSavingsAmount,
    age,
    retire,
    rate
  ) => {
    setNetWorthAtRetirement(retirementAmount);
    setNetWorthToday(currentSavingsAmount);
    updateChartData(retirementAmount, currentSavingsAmount, age, retire, rate);
    setShowDialog(true);
  };

  // const formatNetWorthForChart = (amount) => {
  //   if (amount >= 1000000) {
  //     return `${(amount / 1000000).toFixed(2)}M`;
  //   } else {
  //     return amount.toLocaleString();
  //   }
  // };// new uPDATE
  const updateChartData = (
    futureValue,
    currentSavingsAmount,
    age,
    retire,
    rate
  ) => {
    const endAge = retire + 10;
    const newChartData = [
      { name: 'Today', netWorth: currentSavingsAmount },
    ];
  
    let currentNetWorth = currentSavingsAmount;
    let retirementPointAdded = false;
    let newPeakPoint = { name: `Retirement at age ${retire}`, netWorth: futureValue };
  
    for (let i = age; i <= endAge; i++) {
      if (i <= retire) {
        currentNetWorth += futureValue / (retire - age);
      } else {
        const decumulation = 4;
        currentNetWorth -= currentNetWorth * (decumulation / 100);
        currentNetWorth = Math.round(currentNetWorth);
      }
  
      if (i === retire && !retirementPointAdded) {
        newChartData.push(newPeakPoint);
        retirementPointAdded = true;
      }
      newChartData.push({ name: `Age ${i}`, netWorth: currentNetWorth });
    }
  
    setChartData(newChartData);
    setPeakPoint(newPeakPoint);
  };

  const formatNetWorth = (amount) => {
    if (amount >= 1000000000) {
      return `${(amount / 1000000000).toFixed(2)}B`;
    } else if (amount >= 1000000) {
      return `${(amount / 1000000).toFixed(2)}M`;
    } else {
      return amount.toLocaleString();
    }
  };


  const Loading = () => {
    return (
      <div className="loading-overlay">
        <div className="loading-container">
          <ClipLoader color="#36d7b7" size={50} />
          <p>Processing your request...</p>
        </div>
      </div>
    );
    
    
  };


  return (
    <div className="retirement-story">
      <div className="calculator-brand">
        <div className="story-title">
          <h1>Write your Retirement Story with  </h1>
        </div>
      < div className="logo-vector">
      <div className="logo">
        <Link to="/">
          <img src={Logo} alt="Logo" />
        </Link>
      </div>
      <div className="vector">
        <Link to="">
          <img src={Vector} alt="vector" />
        </Link>
      </div>
      </div>
      </div>

      <div className="story-paragraph">
        <p>
          Your retirement is approaching, but how will it be? Utilize this tool
          to assess your progress towards retirement and explore various
          strategies to achieve your financial goals. We will send you the results for
          future reference.
        </p>
      </div>

      <div className="story-title3">
        <h1>Your retirement story</h1>
      </div>
      <div className="story-title2">
        <h2> How much will I have left in retirement?</h2>
      </div>

      <div className="story-paragraph2">
        <div className="dashInput"
            // onMouseEnter={handleMouseEnter}
            // onMouseLeave={handleMouseLeave}
            // onClick={handleClick}
        >
          

          <p>
            I am
            {/* <div class="entryarea"> */}
            <input
              type="number"
              id="current-age"
              value={currentAge || ''}
              onChange={handleCurrentAge}
              class="form__input"
            />
            {/* <label for="current-age"
            class="form__label">
              Enter your current age
            </label> */}
            {/* <div class="labelline">
            Enter your age
            </div> */}
            {/* <input
              type="text"
              id="Currentage"
              value={currentAge}
              onChange={handleCurrentAge}
              data-tooltip="Enter your age"
              data-info="This information is used to calculate your retirement age."
              onClick={handleClick}
              className={`input ${showInfo ? 'show-info' : ''}`}
            /> */}
            
            {/* </div> */}
            {/* <ul>
              <li>
                <div class="content">
                  <h3>Welcome</h3>
                  <p>Enter your current age in years.</p>
                </div>
              </li>
            </ul> */}
            {/* {(isHovered || isClicked) && (
            <div className="popup">
            Additional Information
            </div> */}

             years old and I plan to retire at age
            <input
              type="number"
              id="retirement-age"
              value={retirementAge}
              onChange={handleRetirementAge}
            />
            
            {/* <input
              type="text"
              id="Retirementage"
              value={retirementAge}
              onChange={handleRetirementAge}
            /> */}
            {/* <ul>
              <li>
                <div class="content">
                
                  <h3>Retirement age</h3>
                  <p>Enter the age at which you plan to retire.</p>
    
                </div>
              </li>
            </ul> */}
            . I make
            <input
              type="number"
              id="current-income"
              value={currentIncome}
              onChange={handleCurrentIncome}
            />
            {/* <input
              type="text"
              id="Currentincome"
              value={currentIncome}
              onChange={handleCurrentIncome}
            /> */}
            {/* <ul>
              <li>
                <div class="content">
                
                  <h3>Amount you make</h3>
                  <p>Enter your current annual income in your local currency.</p>
                
                </div>
              </li>
            </ul> */}
            a year and save
            {/* <input
              type="number"
              id="monthly-savings"
              value={monthlySavings}
              onChange={handleMonthlySavings}
            /> */}
            {/* Or */}
            <input
              type="number"
              id="monthly-savings%"
              value={monthlySavingsPercentage || ''}
              onChange={handleMonthlySavingsPercentage}
            />
            {/* <input
              type="text"
              id="Monthlysavings"
              value={monthlySavingsPercentage}
              onChange={handleMonthlySavingsPercentage}
            /> */}
            {/* <ul>
              <li>
                <div class="content">
                
                  <h3>Retirement age</h3>
                  <p>Enter your current annual income in your local currency.</p>
    
                </div>
              </li>
            </ul> */}
            % of my income annually for retirement. I've already saved
             <input
              type="number"
              id="current-savings"
              value={currentSavings}
              onChange={handleCurrentSavings}
            /> 
            {/* <input
              type="text"
              id="Currentsavings"
              value={currentSavings}
              onChange={handleCurrentSavings}
            />  */}
            
            for retirement and I think I'll need
           
           <input
              type="number"
              id="retirement-income-percentage"
              value={retirementIncomePercentage || ''}
              onChange={handleRetirementIncomePercentage}
            />
            % of my current income in retirement.  I expect an annual return of
            <input
              type="number"
              id="annual-return"
              value={annualReturn || ''}
              onChange={handleAnnualReturn}
            />
            {/* <input
              type="text"
              id="Annualreturn"
              value={annualReturn}
              onChange={handleAnnualReturn}
            /> */}
            % from my retirement savings.
          </p>
          <dashInput />
          <button className="calculate-btn" onClick={calculate}>
            Calculate
          </button>
          {error && <p className="error-message">{error}</p>}
        </div>
        <dashInput />
      </div>
      
      


      {/* Render the chart */}
      <div className="chart-container">
        <div className="result-container">
          <div className="net-worth-container">
            <div className="net-worth-today" data-tooltip-id="net-worth-today-tooltip" data-tooltip-content="Your current net worth">
               <h3>KES {formatNetWorth(netWorthToday)}</h3>
              <p>Net worth today<span className="tooltip-icon">?</span></p>
              <Tooltip id="net-worth-today-tooltip" />
            </div>
            <div className="net-worth-retirement" data-tooltip-id="net-worth-retirement-tooltip" data-tooltip-content="Your estimated net worth at retirement">
              <h3>KES {formatNetWorth(netWorthAtRetirement)}</h3>
              <p>Net worth at retirement<span className="tooltip-icon">?</span></p>
              <Tooltip id="net-worth-retirement-tooltip" />
            </div>
          </div>
        </div>


        <ResponsiveContainer width="100%" aspect={4 / 2}>
          <AreaChart data={chartData}>
            <XAxis dataKey="name" interval={10} />
            <YAxis tickFormatter={(value) => {
              if (value >= 1000000) {
                return `${value / 1000000}M`;
              } else {
                return value;
              }
            }} />
            <Tooltip />
            {/* <CartesianGrid strokeDasharray="3 3" /> */}
            <Legend iconType="circle" />
            <Area type="monotone" dataKey="netWorth" stroke="#78350F" fill="url(#areaFill)" strokeWidth={2} />
            {peakPoint && <ReferenceDot x={peakPoint.name} y={peakPoint.netWorth} r={5} fill="rgb(223, 159, 21)" />}
            <defs>
              <linearGradient id="areaFill" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#78350F" stopOpacity={0.8}/>
                <stop offset="95%" stopColor="#78350F" stopOpacity={0}/>
              </linearGradient>
            </defs>
          </AreaChart>
        </ResponsiveContainer>
      </div>
      {showDialog && (
 <DialogBox
 message={
   <div>
     Your Retirement Story is ready! <br />
     We would like to send the story for future reference. <br />
     Press OK to tell us where to send the story.
   </div>
 }
    onConfirm={handleDialogConfirm}
    onCancel={handleDialogCancel}
    setShowDialog={setShowDialog}
    setShowModal={setShowModal}
  />
)}
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={() => setShowModal(false)}>&times;</span>
            <div className="reg-card">
              <a href="/">
                <img src="kiota-logo-1.svg" alt="Logo" id="logo" />
              </a>

              <form onSubmit={(e) => handleWaitListSubmit(e, { name: e.target.name.value, email: e.target.email.value, phone: e.target.phone.value })}>
  <div className="input-wrapper">
    <label htmlFor="name">Name</label>
    <input
      type="text"
      id="name"
      name="name"
      required
    />
  </div>

  <div className="input-wrapper">
    <label htmlFor="email">Email</label>
    <input
      type="email"
      id="email"
      name="email"
      required
    />
  </div>

  <div className="input-wrapper">
    <label htmlFor="phone">Phone Number</label>
    <input
      type="tel"
      id="phone"
      name="phone"
      required
    />
  </div>

  <button type="submit" className="register-btn">
    Join the WaitList
  </button>
</form>

            </div>
          </div>
        </div>
      )}
        {isLoading && <Loading />}
    </div>
  );
};

export default RetirementStory;